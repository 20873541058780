import { CreatePopup, Textbox, Textarea } from 'components/generic';

export default function AddEvent(props) {

    const { handleCreatePopup, eventExists, disableSave, eventObj, addEventInput } = props;

    return (
        <>
            <CreatePopup disableSave={disableSave} handleCreatePopup={handleCreatePopup}>
                <h3>Add Event</h3>
                <Textbox label='Event ID' mandatory value={eventObj.et_code} placeholder="Eg: arrangement" error={eventExists} errorMessage={'Event already exists.'} name="et_code" onChange={addEventInput} maxlength={10} />
                <Textbox label='Event Name' mandatory value={eventObj.et_name} placeholder="Eg: Arrangement" name="et_name" onChange={addEventInput} maxlength={30} />
                <Textarea label='Event Description' value={eventObj.et_description} name="et_description" placeholder="Eg: Event for arrangement" onChange={addEventInput} maxlength={50} />
            </CreatePopup>
        </>
    )
}