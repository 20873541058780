import { useContext } from 'react';
import { isEmpty } from 'lodash';
import { ContentWrapper, Textbox, Textarea, Selectmenu } from 'components/generic';
import { MatrixContext } from 'providers';

export const MatrixDetails = (props) => {
    const { doesExist, handleInput, handleDrop, manual, disableApp } = props;
    const { createObj, appList, dockerState } = useContext(MatrixContext);
    const [setvalue] = createObj;

    // handle selected
    const getSelected = (list, id) => {
        return list?.filter(ele => ele.value === id);
    }

    return (
        <>
            <ContentWrapper last manual={manual}>
                <div className="row">
                    <div className={manual ? 'col-12' : 'col-4'}>
                        <Selectmenu label='Application' isSearchable={true} mandatory options={appList} onChange={(e) => handleDrop('sa_id', e)} value={getSelected(appList, setvalue?.sa_id)} isDisabled={dockerState === 'view' || disableApp} />
                        <Textbox label='Matrix Name' maxlength={30} mandatory value={setvalue?.mx_name || ''} placeholder="Eg: Litigation matrix" name="mx_name" onChange={handleInput} error={!isEmpty(doesExist?.data) && (dockerState === 'add' || dockerState === 'copy')} errorMessage={doesExist?.message} disabled={dockerState === 'view' || dockerState === 'edit'} />
                        <Textarea label='Matrix Description' maxlength={50} value={setvalue?.mx_description || ''} name="mx_description" onChange={handleInput} placeholder="Eg: Workflow for litigation approval" disabled={dockerState === 'view'} />
                    </div>
                </div>
            </ContentWrapper>
        </>
    )
}