import axios from "axios";

const { REACT_APP_BASE_URL, REACT_APP_EID } = process.env;
const headerOutToken = { 'e-id': REACT_APP_EID, 'refresh-token': localStorage.getItem('_rc'), 'token': localStorage.getItem('_ac') };

const client = axios.create({ baseURL: REACT_APP_BASE_URL });

const refreshToken = () => {
    axios.get('/refresh-token', { headers: headerOutToken }).then(res => {
        localStorage.setItem('_ac', res?.data?.data?.access_token);
        window.location.reload();
    });

}

export const request = ({ ...options }) => {
    client.defaults.headers.common['e-id'] = REACT_APP_EID;
    client.defaults.headers.common['token'] = localStorage.getItem('_ac') !== null ? localStorage.getItem('_ac') : '';
    const onSuccess = response => response
    const onError = error => {
        error.response.status === 401 && refreshToken();
        return error.response
    }
    return client(options).then(onSuccess).catch(onError)
}