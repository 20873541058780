import { useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { useQuery } from 'react-query';
import * as api from 'actions';
import { ReferenceContext } from 'providers';
import { LayoutComponent } from "components/layout";
import WorkFlowMaintenance from "components/pages/workflow-maintenance";
import MatrixMaintenance from "components/pages/matrix-maintenance";
import ConditionalMaintenance from "components/pages/condition-maintenance";
// import { PageNotFound } from 'components/generic';
import LandingPage from "components/pages/landing";
import MissingPriviledge from 'components/pages/missing-privilege';

function ConditionalRouter() {
    const { data: referenceData, refetch } = useQuery('getReferenceData', () => api.getReferenceData(), { refetchOnWindowFocus: false, enabled: false });
    useEffect(() => {
        refetch();
    }, [localStorage.getItem('_ac')]);

    return <>
        {localStorage.getItem('_ac') !== null && <ReferenceContext.Provider value={{ referenceData }}>
            <Route exact path="/workflow-maintenance" component={WorkFlowMaintenance} />
            <Route exact path="/exception-maintenance" component={ConditionalMaintenance} />
            <Route exact path="/matrix-maintenance" component={MatrixMaintenance} />
        </ReferenceContext.Provider>}</>
}

function Routers() {
    // const { NODE_ENV } = process.env;
    // const dirPath = NODE_ENV === 'development' ? '' : '/approval';
    return (
        <>
            <Router>
                <LayoutComponent>
                    <Switch>
                        <Route component={LandingPage} exact path="/" />
                        <Route component={MissingPriviledge} exact path="/missing-privilege" />
                        <ConditionalRouter />
                    </Switch>
                </LayoutComponent>
                {/* <Route exact path="*" component={PageNotFound} /> */}
            </Router>
        </>
    )
}

export default Routers
