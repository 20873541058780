import { useLocation, useHistory, withRouter } from 'react-router-dom';

import './style.scss';

function MissingPriviledge() {
    const history = useHistory();

    const backToLogin = () => {
        history.push({ pathname: '/' });
    }

    return (
        <>
            <div className="priviledges">
                <div className="priviledges__content">
                    <h2>Oops! <br />You dont have access for approval portal</h2>
                    <p>Please contact your system administrator</p>
                    <div className="priviledges__button" onClick={backToLogin} >Back to login</div>
                </div>
            </div>
        </>
    )
}

export default withRouter(MissingPriviledge);
