import PropTypes from 'prop-types';
import { Popup } from '..';
import { Images } from 'assets/Images';
import { ButtonRow } from '..';

import './style.scss';

export default function PopupConfimation(props) {

    const { heading, content, showstatus, handleConfimation, handleChecked, activeStatus } = props;

    return (
        <>
            <Popup small alignleft>
                <img src={Images.warning} alt="warning" />
                <h2>{heading}</h2>
                <p>{content}</p>
                {showstatus && <div className="status">
                    <h5>Status</h5>
                    <div className="status__input">
                        <input type="radio" name="status" id="published"
                            checked={activeStatus === 'PUBLISHED'} onChange={(e) => handleChecked(e.target.checked, 'PUBLISHED')} />
                        <label>Published</label>
                        <input type="radio" name="status" id="draft"
                            checked={activeStatus === 'DRAFT'} onChange={(e) => handleChecked(e.target.checked, 'DRAFT')} />
                        <label>Draft</label>
                    </div>
                </div>}
                <ButtonRow label="No" label1="Yes" handleButtonClick={handleConfimation} />
            </Popup >

        </>
    )
}

PopupConfimation.propTypes = {
    heading: PropTypes.string,
    content: PropTypes.string,
    showstatus: PropTypes.bool,
    handleConfimation: PropTypes.func
}

PopupConfimation.defaultProps = {
    heading: '',
    content: '',
    showstatus: false,
    handleConfimation: () => { }
}


