export const Images = {
    add: 'images/add-24px.svg',
    addBlue: 'images/add-blue.svg',
    close: 'images/close.svg',
    closeIcon: 'images/close-icon.svg',
    drag: 'images/drag.svg',
    editIcon: 'images/edit-icon.svg',
    error: 'images/error.svg',
    expand: 'images/expand.svg',
    expand2: 'images/expand2.svg',
    generate: 'images/generate.svg',
    gridView: 'images/grid-view.svg',
    errorImage: 'images/400.png',
    info: 'images/info.svg',
    listView: 'images/list-view.svg',
    minimize: 'images/Minimize.svg',
    remove: 'images/Remove.svg',
    reportLanding: 'images/reports.gif',
    success: 'images/success.svg',
    warningInfo: 'images/warning.svg',
    search: 'images/search.svg',
    validate: 'images/validate.svg',
    copyDark: 'images/copy-dark.svg',
    viewDark: 'images/view-dark.svg',
    deleteIcon: 'images/delete-icon2.svg',
    arrowDown: 'images/arrow-down.svg',
    calendar: 'images/calendar.svg',
    dropdown: 'images/dropdown.svg',
    noRecord: 'images/no-record.svg',

    // docker
    edit: 'images/edit.svg',
    copy: 'images/copy.svg',
    delete: 'images/delete.svg',
    view: 'images/view.svg',
    download: 'images/download.svg',
    pluse: 'images/docker/pluse.svg',
    deleteP: 'images/docker/delete.svg',
    warning: 'images/warning-popup.svg',
    createNew: 'images/create-scope.svg',
    deleteBlack: 'images/delete-black.svg',

    // sidebar
    logo: 'images/sidebar/logo.svg',
    condition: 'images/sidebar/condition.svg',
    workflow: 'images/sidebar/workflow.svg',
    matrix: 'images/sidebar/matrix.svg',
    banner: 'images/banner.svg',
    logout: 'images/sidebar/logout.svg'
};