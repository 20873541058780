import classNames from 'classnames';
import * as moment from 'moment';
import ToggleSwitch from 'components/generic/toggle-switch';
import './style.scss';
import { startCase } from 'lodash';

const CardComponent = (props) => {
    const { list, index, checkbox, handleToggle, checked, toggleOn, toggle, add, remove, border, handleAddClick, handleChecked } = props;

    // date formate
    function dateFormate(value) {
        return moment(value).format('DD-MM-YYYY');
    }

    return (
        <>
            <div className="card__col">
                <div className={classNames({ 'card__block': true, 'card--border': border })}>
                    <div className="card__wrap">
                        <div className="card__section">
                            <h4>{list?.wf_name || list?.mx_name || list?.cn_name} <span className='card__section--version'>v{list?.wf_version || list?.mx_version || list?.cn_version}</span></h4>
                            <p>{list?.mx_description || list?.wf_description || list?.cn_description}</p>
                            <span className='card--yellow'>{list?.et_name}</span>
                            {list?.cn_severity && <span className={classNames({ 'card--red': list?.cn_severity === 'high', 'card--orange': list?.cn_severity === 'medium', 'card--green': list?.cn_severity === 'low' })}>{list?.cn_severity} </span>}
                        </div>
                        {checkbox && <div className="card__section">
                            <input id={index} type="checkbox" onChange={(e) => handleChecked(e.target.checked, list)} checked={checked} />
                        </div>}
                    </div>
                    <div className="card__wrap">
                        <div className="card__section">
                            <span>{list?.updatedBy} </span>
                            <span>{dateFormate(list?.updatedAt)}</span>
                        </div>
                        <div className="card__section">
                            <div className={classNames({ 'card__status': true, 'card--publish': (list?.wf_mode || list?.mx_mode || list?.cn_mode) === 'PUBLISHED', 'card--draft': (list?.wf_mode || list?.mx_mode || list?.cn_mode) === 'DRAFT' })}> {startCase((list?.wf_mode || list?.mx_mode || list?.cn_mode)?.toLowerCase()) || 'Published'} </div>
                        </div>
                        <div className="card__section">
                            {toggle && <ToggleSwitch checked={toggleOn} onChange={(e) => handleToggle(e, list)} />}
                            {add && <div className="card--add" onClick={() => handleAddClick(list, 'add')}>+ Add</div>}
                            {remove && <div className="card--remove" onClick={() => handleAddClick(list, 'remove')}>- Remove</div>}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CardComponent;