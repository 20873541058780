import { useEffect, useState } from "react";
import reactDragula from "react-dragula";
import { each, filter } from "lodash";
import { DragDropContainer } from 'react-drag-drop-container';
import RuleTemplate from "../rule-template";
import GroupTemplate from "../group-template";
import { Images } from 'assets/Images';
import './styles.scss';

export default function RuleEngine(props) {

  const { rule, viewOnly, listHeight, rulesHeight, tablist, factListEvent, expressionList, operatorDetailEvent, listColors, data, createFact } = props;

  const [activeTab, setActiveTab] = useState('Facts');
  const [filterList, setFilterList] = useState([]);
  const [dragObj, setDragObj] = useState({});

  let type = 'default';

  useEffect(() => {
    reactDragula({
      isContainer: (el) => { return el.classList.contains('group-container') },
      moves: (el, source, handle, sibling) => {
        if (handle.classList.contains('drag-handler')) return true;
        return false;
      }
    })
  }, [])

  useEffect(() => {
    setFilterList(data.filteredFacts);
    renderRule(rule);
  }, [data]);

  useEffect(() => {
    activeTab === 'Facts' ? setFilterList(data.filteredFacts) : setFilterList(expressionList);
  }, [data.filteredFacts, expressionList, activeTab]);

  // clone group template
  const cloneGroup = () => {
    let group = document
      .getElementsByClassName('group-template')[0]
      ?.cloneNode(true);
    group?.classList?.remove('group-template');
    group?.classList?.add('group', 'show-group');
    return group;
  };

  // clone rule template
  const cloneRule = () => {
    let rule = document
      .getElementsByClassName('rule-template')[0]
      ?.cloneNode(true);
    rule?.classList?.remove('rule-template');
    rule?.classList?.add('rule', 'show-rule');
    return rule;
  };

  // render default json rule
  const render = (renderData) => {
    if (Array.isArray(renderData)) {
      let html = renderData.map((r) => render(r)).join('');
      return html;
    } else {
      let html = '';
      if (renderData.all) {
        const group = cloneGroup();
        const sel = group.querySelectorAll("select option[value='ALL']")[0];
        sel.setAttribute('selected', 'selected');
        let innerHtml = render(renderData.all);
        group.querySelectorAll('.group-container')[0].innerHTML = innerHtml;
        html = group.children?.length > 0 ? group.outerHTML : html;
        group.remove();
      }
      if (renderData.any) {
        const group = cloneGroup();
        const sel = group.querySelectorAll("select option[value='ANY']")[0];
        sel.setAttribute('selected', 'selected');
        let innerHtml = render(renderData.any);
        group.querySelectorAll('.group-container')[0].innerHTML = innerHtml;
        html = group.children?.length > 0 ? group.outerHTML : html;
        group.remove();
      }
      if (!renderData.all && !renderData.any) {
        const rule = cloneRule();
        const row = rule.children;
        row[0].setAttribute('value', getterSetter(renderData?.fact, 'fact', 'set'), true); //Fact rerendering

        const opSelected = row[1]; //Operator rerendering
        opSelected.remove(0);
        opSelected.value = getterSetter(renderData?.operator, 'operator', 'set');

        const op2 = opSelected.options;
        const opIndex = op2.selectedIndex;
        [op2[0], op2[opIndex]] = [op2[opIndex], op2[0]];
        row[2].children[0].setAttribute('value', renderData?.value, true); //value rerendering
        html = rule.children?.length > 0 ? rule.outerHTML : html;
        rule.remove();
      }
      return html;
    }
  };

  // get set key value for facts
  const getterSetter = (element, selectType, type) => {
    let [key, value] = ['', ''];
    switch (selectType) {
      case 'fact':
        data.factName?.forEach((ele) => {
          key = ele?.fa_name === element ? ele?.fa_key : key;
          value = ele?.fa_key === element ? ele?.fa_name : value;
        });
        break;
      case 'operator':
        data.opName?.forEach((ele) => {
          key = ele?.op_name === element ? ele.op_key : key;
          value = ele?.op_key === element ? ele.op_name : value;
        });
        break;
      default:
        break;
    }
    return type === 'get' ? key : value;
  };

  // render the rule and attach eventlistener
  const renderRule = (renderer) => {
    const html = render(renderer);
    document.getElementsByClassName('rule-definition')[0].innerHTML = html;
    const addGrp = document.getElementsByClassName('insert-group'); //to add event listener to each click/change
    const rmvGrp = document.getElementsByClassName('remove-group');
    const addRule = document.getElementsByClassName('insert-rule');
    const rmvRule = document.getElementsByClassName('remove-rule');
    const rule = document.getElementsByClassName('rule');
    renderClick(addGrp, 'insert-group');
    renderClick(rmvGrp, 'remove-group');
    renderClick(addRule, 'insert-rule');
    renderClick(rmvRule, 'remove-rule');
    renderClick(rule, 'factName');
  };

  // attach event listener for render click
  const renderClick = (collection, className) => {
    switch (className) {
      case 'insert-group':
        each(collection, (el) =>
          el.addEventListener('click', () => addGroup(el))
        );
        break;
      case 'remove-group':
        each(collection, (el) =>
          el.addEventListener('click', () => removeGroup(el))
        );
        break;
      case 'insert-rule':
        each(collection, (el) =>
          el.addEventListener('click', () => addRule(el))
        );
        break;
      case 'remove-rule':
        each(collection, (el) =>
          el.addEventListener('click', () => removeRule(el))
        );
        break;
      case 'factName':
        each(collection, (i, e) => {
          const rmv = i.children;
          rmv[2]?.children[0].addEventListener('keyup', () => validateValue('event', rmv));
          rmv[2]?.children[1]?.addEventListener('click', () => handleRemoveValue(rmv));
        });
        break;
      default:
        break;
    }
  }

  // handle add group
  const addGroup = (e) => {
    const existGroup = e.closest('.group');
    const container = existGroup.querySelector('.group-container');
    const group = cloneGroup();
    container.appendChild(group);
    const newGrp = group?.children[0]?.children[0]?.children;
    newGrp[1].addEventListener('click', () => addGroup(newGrp[1]));
    newGrp[2].addEventListener('click', () => addRule(newGrp[2]));
    newGrp[3].addEventListener('click', () => removeGroup(newGrp[3]));
  };

  // handle remove group
  const removeGroup = (e) => {
    let group = e.closest('.group');
    if (group.querySelector('.rule')?.children || group.querySelector('.group')?.children) {
      window.confirm('group may have child rules/groups, Are you sure you want to delete') && e.closest('.group').remove();
    } else {
      e.closest('.group').remove();
    }
  };

  // handle add rule
  const addRule = (e) => {
    const rule = cloneRule();
    const parent = e.closest('.group').querySelector('.group-container');
    parent.appendChild(rule);
    let rmv = rule.children;
    rmv[2]?.children[0].addEventListener('keyup', () => validateValue('event', rmv));
    rmv[2]?.children[1]?.addEventListener('click', () => handleRemoveValue(rmv));
    rmv[3].addEventListener('click', () => removeRule(rmv[3]));
  };

  // handle remove rule
  const removeRule = (e) => {
    e.closest('.rule').remove();
  };

  // set operator on fact drop
  const setOperator = (element) => {
    element[1].options[0].value === '' && element[1].remove(0);
    const val = element[0].value;
    data.factName?.forEach((i, e) => {
      type = i?.fa_name === val ? i?.fa_allowed_data_type : type;
    });
    let typ = getDataType(type);
    operatorDetailEvent?.forEach((e) => {
      data.operSelect = e.type === typ ? e.operator : data.operSelect;
    });
    const op = element[1];
    while (op.firstChild) op.removeChild(op.firstChild);
    filter(data.operSelect, ele => {
      const option = new Option(ele, ele);
      op.appendChild(option);
    });
    data.operSelect = operatorDetailEvent && operatorDetailEvent[0]?.operator;
  };

  // get data type of fact
  const getDataType = (type) => {
    if (type?.includes('array')) return 'AR';
    if (type?.includes('string')) return 'AN';
    if (type?.includes('number')) return 'NU';
    return 'default';
  };

  // validate input for environmental variable
  const validateValue = (event, element) => {
    const fact = element[0].value;
    const val = element[2]?.children[0].value;
    const op = element[1];
    const factObj = data.enviromentalList?.filter(ele => ele.fa_name === fact);
    if (val === '{{' && factObj?.length > 0 && event?.key !== 'Backspace') {
      element[2].children[0].value = `{{${factObj[0]?.fa_collection_field}}}`;
      while (op.firstChild) op.removeChild(op.firstChild);
      filter(['In', 'Not in'], ele => {
        const option = new Option(ele, ele);
        op.appendChild(option);
      });
    }
  };

  // handle remove value
  const handleRemoveValue = (e) => {
    e[2].children[0].value = '';
  }

  // handle fact tab change
  const handleFactTab = (tab) => {
    setActiveTab(tab);
    const obj = {
      'Facts': () => { setFilterList(factListEvent); },
      'Expression': () => { setFilterList(expressionList); },
    }
    obj[tab]();
  }

  // handle on search fact
  const onSearch = (val) => {
    const obj = {
      'Facts': factListEvent,
      'Expression': expressionList
    }
    const array = obj[activeTab];
    setFilterList(searchFunc(array, val.target.value));
  }

  // handle search function for fact search
  const searchFunc = (items, text) => {
    return items.filter(el => {
      return Object.values(el).some(val =>
        String(val).toLowerCase().includes(text.toLowerCase())
      );
    });
  }

  // handle action create fact
  const handleAction = (e, action) => {
    createFact({ obj: e, action: action, tab: activeTab });
  }

  // handle dragged set object
  const handleDragged = (fact) => {
    setDragObj(fact);
  }

  // handle set dropped value
  const dropped = (obj, e) => {
    if (e.className === 'drag-box' && !viewOnly) {
      e.value = obj.fa_name;
      setOperator(e?.parentElement?.children);
    }
  };

  return (
    <div className="envelope">
      <div className="envelope__facts">

        <div className="envelope__search">
          <img src={Images.search} alt="search" />
          <input type="text" placeholder="Search" onKeyUp={(e) => onSearch(e)} />
        </div>

        <div className="envelope__tablist">
          <ul>{tablist?.map((tab, index) =>
          (<li className={activeTab === tab ? 'is-active' : ''} key={index} onClick={(e) => handleFactTab(tab)}>
            <p>{tab}</p>{activeTab === tab && <span>{filterList?.length}</span>}
          </li>))}</ul>

          <div className="envelope__factlist" style={listHeight}>
            {filterList?.map((fact, index) =>
              <DragDropContainer targetKey='fact' key={index} dragData={dragObj} onDragEnd={(fact, event) => dropped(fact, event)} dragClone={viewOnly ? true : false} dragElemOpacity={viewOnly ? 0 : 1}>
                <div className="list-item" onMouseDown={() => handleDragged(fact)}>
                  <span style={{ 'backgroundColor': listColors[index % listColors?.length] }}>{fact?.fa_name?.substring(0, 2)}</span>
                  <p>{fact?.fa_name}</p>
                  {activeTab === 'Expression' && !viewOnly && <div className="tooltip">
                    {/* <img src={Images.copyDark} alt="copy" onClick={() => handleAction(fact, 'copy')} /> */}
                    <img src={Images.viewDark} alt="view" onClick={() => handleAction(fact, 'view')} />
                  </div>}
                </div>
              </DragDropContainer>)}
          </div>

          {!viewOnly && <div className="create-fact"><img src={Images.addBlue} alt="add" onClick={() => handleAction({}, 'add')} />
          </div>}
        </div>
      </div >

      <div className="envelope__engine" style={rulesHeight}>
        <div className="rule-definition"></div>
        <GroupTemplate viewOnly={viewOnly} />
        <RuleTemplate viewOnly={viewOnly} operSelect={data.operSelect} />
      </div>
    </div >
  );
}


