import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import RuleEngine from "./rule-engine/engine";
import CreateExpression from "./create-expression";
import CreateFact from "./create-fact";
import RuleModal from "./rule-modal";
import { Images } from 'assets/Images';
import { colors, newRule, operatorList } from "config";
import './styles.scss';

export default function DecisionBlock(props) {

  const { title, name, description, severity, state, viewOnly, rule, tablist, factListEvent, expressionList, operatorListEvent, listHeight, rulesHeight, operators, saveRule, createFactObj, addFactInput, dataType,
    onModalSubmit, onCreateFact, onCreatExpression, onSaveRule, factExists } = props;

  const [showModal, setShowModal] = useState(false);
  const [showCreate, setShowCreate] = useState('');
  const [fact, setFact] = useState({ action: 'add', name: '', formula: '' });
  const [data, setData] = useState({ factName: [], enviromentalList: [], filteredFacts: [], opName: [], operSelect: [] });
  const operatorDetailEvent = operatorList;

  useEffect(() => {
    setData({
      factName: sortByName(factListEvent?.concat(expressionList)),
      enviromentalList: sortByName(factListEvent?.concat(expressionList))?.filter(ele => ele?.fa_type?.includes('ENVIRONMENT_VARIABLE')),
      filteredFacts: sortByName(factListEvent),
      opName: operatorListEvent,
      operSelect: operatorDetailEvent && operatorDetailEvent[0]?.operator
    });
  }, [factListEvent, expressionList, operatorDetailEvent, operatorListEvent]);

  useEffect(() => {
    saveRule && saveRules();
  }, [saveRule])

  // toggle show modal
  const toggleModal = () => {
    setShowModal(!showModal);
  };

  // sort fact list
  const sortByName = (data) => {
    data && data.sort((a, b) => {
      const fa = a?.fa_name?.toLowerCase();
      const fb = b?.fa_name?.toLowerCase();
      return fa < fb ? -1 : fa > fb ? 1 : 0;
    });
    return data;
  };

  // handle save button
  const saveRules = () => {
    const firstChildren = document.querySelector('.rule-definition > .group');
    const rule = genRules(firstChildren);
    const ruleStr = JSON.stringify(rule, null, 4);
    onSaveRule({ rule: rule, ruleStr: ruleStr });
  }

  // handle generate Rule json
  const genRules = (block) => {
    const child = block?.children;
    if (block.classList.contains('rule')) {
      const factName = block?.children[0].value;
      const operator = block?.children[1].value;
      const value = block?.children[2]?.children[0].value;
      return {
        field_key: getFieldKey(factName),
        fact: getterSetter(factName, 'fact', 'get'),
        operator: getterSetter(operator, 'operator', 'get'),
        value: value,
      };
    } else if (child[0].querySelector('select').value === 'ALL') {
      return { all: getAnyAllJson(block?.children[1].children) };
    } else if (child[0].querySelector('select').value === 'ANY') {
      return { any: getAnyAllJson(block?.children[1].children) };
    }
    return;
  };

  // handle any all in json
  const getAnyAllJson = (groupArray) => {
    let dataList = [];
    Array.from(groupArray)?.forEach((i, e) => dataList.push(genRules(i)));
    return dataList;
  }

  // get field key for fact
  const getFieldKey = (fact) => {
    let key = '';
    data.factName?.forEach((ele) => key = ele?.fa_name === fact ? ele?.fa_collection_field : key);
    return key;
  }

  // getting setting key value of fact for rendering
  const getterSetter = (element, selectType, type) => {
    let [key, value] = ['', ''];
    switch (selectType) {
      case 'fact':
        data.factName?.forEach((ele) => {
          key = ele?.fa_name === element ? ele?.fa_key : key;
          value = ele?.fa_key === element ? ele?.fa_name : value;
        });
        break;
      case 'operator':
        data.opName?.forEach((ele) => {
          key = ele?.op_name === element ? ele.op_key : key;
          value = ele?.op_key === element ? ele.op_name : value;
        });
        break;
      default:
        break;
    }
    return type === 'get' ? key : value;
  };

  // handle modal submit click
  const handleModalSubmit = (e, name, description, sev) => {
    setShowModal(!showModal);
    (e === 'save') && onModalSubmit({ name: name, description: description, severity: sev });
  }

  // handle create popup button
  const createFactProp = (e) => {
    setShowCreate(e.tab);
    setFact({ action: e?.action, name: e.obj?.fa_name || '', formula: e.obj?.fa_formula || '' })
  }

  // handle create expression object
  const handleCreateExpression = (e) => {
    setShowCreate('');
    e.status === 'submit' && onCreatExpression(e);
  }

  // handle create fact object
  const handleCreateFact = (e) => {
    setShowCreate('');
    onCreateFact(e)
  }

  return (
    <React.Fragment>
      {showCreate === 'Facts' && <CreateFact fact={fact} createFactObj={createFactObj} dataType={dataType} handleCreatePopup={handleCreateFact} addFactInput={addFactInput} factExists={factExists} />}
      {showCreate === 'Expression' && <CreateExpression fact={fact} factlist={factListEvent} operators={operators} onCreateFact={handleCreateExpression} />}

      <div className="wrapper">
        <div className="wrapper__header" >
          <h4>{title}&nbsp;<span>{name}&nbsp;{!viewOnly && <img src={Images.editIcon} alt="edit" onClick={toggleModal} />}
            {showModal && <RuleModal name={name} description={description} severity={severity} state={state} handleSubmit={handleModalSubmit} />}</span >
          </h4 >
        </div >

        <RuleEngine rule={rule || newRule} viewOnly={viewOnly} listHeight={listHeight} rulesHeight={rulesHeight} tablist={tablist} factListEvent={factListEvent} expressionList={expressionList} operatorDetailEvent={operatorDetailEvent} listColors={colors} data={data} createFact={createFactProp} />
      </div>
    </React.Fragment>
  );
}


DecisionBlock.protoTypes = {
  rule: PropTypes.object,
  title: PropTypes.string,
  name: PropTypes.string,
  description: PropTypes.string,
  tablist: PropTypes.array,
  factListEvent: PropTypes.array,
  expressionList: PropTypes.array,
  operatorListEvent: PropTypes.array,
  listHeight: PropTypes.object,
  rulesHeight: PropTypes.object,
}

DecisionBlock.defaultProp = {
  rule: {},
  title: 'Creating Rules for',
  name: 'Name',
  description: 'Description',
  tablist: [],
  factListEvent: [],
  expressionList: [],
  operatorListEvent: [],
  listHeight: {},
  rulesHeight: {},
}
