import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Images } from 'assets/Images';

import './style.scss';

export default function Toaster(props) {
    const { information, error, success, warning, center, header, content, undo, buttontext, hideclose, handleUndoClose, top } = props;

    return (
        <>
            <div className={classNames({ 'alert': true, 'alert--top': top })} >
                <div className={classNames({ 'alert__message': true, 'alert__message--info': information, 'alert__message--error': error, 'alert__message--success': success, 'alert__message--warning': warning, 'alert__message--center': center })}>
                    <div className="alert__message__icon"><img src={success ? Images.success : error ? Images.error : warning ? Images.warningInfo : Images.info} alt="" /></div>
                    <div className="alert-message__content">
                        <h2>{header}</h2>
                        <p>{content}</p>
                    </div>
                    {undo && <div className="alert__message__undo" onClick={() => handleUndoClose('undo')}>{buttontext ? buttontext : 'Undo'}</div>}
                    {!hideclose && <div className="alert__message__close" onClick={() => handleUndoClose('close')}><img src={Images.close} alt="X" />
                    </div>}
                </div>
            </div>
        </>
    )
}

Toaster.propTypes = {
    information: PropTypes.bool,
    error: PropTypes.bool,
    success: PropTypes.bool,
    warning: PropTypes.bool,
    center: PropTypes.bool,
    header: PropTypes.string,
    content: PropTypes.string,
    undo: PropTypes.bool,
    buttontext: PropTypes.string,
    hideclose: PropTypes.bool,
}

Toaster.defaultProps = {
    information: false,
    error: false,
    success: false,
    warning: false,
    center: false,
    header: '',
    content: '',
    undo: false,
    buttontext: '',
    hideclose: false,
}
