import Switch from "react-switch";
import PropTypes from 'prop-types';

export default function ToggleSwitch(props) {
    const { label, id, onChange, checked, disabled } = props;
    return (
        <>
            <div className="toggle-switch">
                <label>{label}</label>
                <Switch id={id} onChange={onChange} checked={checked} disabled={disabled} uncheckedIcon={false}
                    checkedIcon={false} height={15} width={30} boxShadow="0px 0px 1px 2px rgba(0, 112, 253, 1)" onColor="#0070fd"
                    onHandleColor="#ffffff" className="toggle-bg"
                    handleDiameter={18} activeBoxShadow="0px 0px 1px 2px rgba(0, 112, 253, 1)" />
            </div>
        </>
    );
}

ToggleSwitch.propTypes = {
    id: PropTypes.string,
    label: PropTypes.string,
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
    checked: PropTypes.bool,
}

ToggleSwitch.defaultProps = {
    label: "",
    disabled: false,
    checked: false,
}