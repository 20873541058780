import PropTypes from 'prop-types';
import classNames from 'classnames';
import './style.scss';

export default function PopupCard(props) {
    const { children, left, top } = props;
    return (
        <div className={classNames({ "popup-card": true, "popup-card--left": left, "popup-card--top": top })}>
            {children}
        </div>
    )
}

PopupCard.propTypes = {
    children: PropTypes.element.isRequired
}

