import { useState, useContext, useEffect } from 'react';
import { map, isEmpty, cloneDeep, filter, find } from 'lodash';
import classNames from 'classnames';
import { ReferenceContext, MatrixContext } from 'providers';
import { Button, ContentWrapper, PopupCard, Selectmenu, Textbox } from 'components/generic';
import './style.scss';
import { Images } from 'assets/Images';

export default function Matrix(props) {
    const { roles, handleMxConfig, expand, handleExpandMatrix } = props;

    const referenceData = useContext(ReferenceContext);
    const { createObj, dockerState } = useContext(MatrixContext);
    const [rowRoles, setRowRoles] = useState(roles);
    const [colRoles, setColRoles] = useState(roles);
    const [selectValue, setSelectValue] = useState('');
    const [addCol, setaddCol] = useState([]);
    const [columnIndex, setColumnIndex] = useState(-1);
    const [rowIndex, setRowIndex] = useState(-1);
    const [actionLevel, setActionLevel] = useState({});
    const [dayHrs, setDayHrs] = useState({});
    const [isEditing, setIsEditing] = useState(false);
    const [editingIndex, setEditingIndex] = useState(-1);
    const [mxConfig] = createObj;
    const [moveCol, setMoveCol] = useState(false);

    const level = find(referenceData?.referenceData, el => el.rd_levels);
    const actions = find(referenceData?.referenceData, el => el.rd_actions);

    useEffect(() => {
        if (mxConfig?.mx_config && !isEmpty(mxConfig?.mx_config)) {
            setaddCol(mxConfig.mx_config);
            const rows = mxConfig.mx_config?.map(ele => ele.mx_reqBy);
            const cols = mxConfig.mx_config[0]?.mx_actionedBy?.map(ele => ele.mx_actionedBy);
            const list1 = roles?.filter(ele => !rows?.includes(ele?.value));
            const list2 = roles?.filter(ele => !cols?.includes(ele?.value));
            setRowRoles(list1);
            setColRoles(list2);
        }
    }, [mxConfig.mx_config]);

    const handleDayHrs = ({ target: { name, value } }) => {
        setDayHrs(state => ({ ...state, [name]: value }), []);
    }

    const handleDrop = (name, value) => {
        setActionLevel(state => ({ ...state, [name]: value?.label && value.value ? value : '' }), []);
    }

    const handleAddCard = (rIndex, cIndex, e) => {
        setColumnIndex(cIndex);
        setRowIndex(rIndex);
        showCardPopup(e.clientX);
    }

    const handleEditCard = (rIndex, cIndex, data, e) => {
        setColumnIndex(cIndex);
        setRowIndex(rIndex);
        setDayHrs(data.mx_ola);
        const rdLevel = find(level?.rd_levels, el => el.value === data.rd_level);
        const rdAction = find(actions?.rd_actions, el => el.value === data.rd_action);
        setActionLevel({ rd_level: rdLevel, rd_action: rdAction });
        showCardPopup(e.clientX);
    }

    const handleDeleteCard = (rIndex, cIndex, data) => {
        addCol[rIndex].mx_actionedBy[cIndex].rd_action = '';
        // addCol[rIndex].mx_actionedBy[cIndex].rdAction = '';
        addCol[rIndex].mx_actionedBy[cIndex].rd_level = '';
        addCol[rIndex].mx_actionedBy[cIndex].mx_ola = {};
        mxConfig['mx_config'] = addCol;
        resetIndex();
        resetData();
    }

    const handleIndexCard = (rIndex, cIndex) => {
        removeColMatrix();
        const { rd_level, rd_action } = actionLevel;
        addCol[rIndex].mx_actionedBy[cIndex]['mx_ola'] = { mx_days: dayHrs?.mx_days || 0, mx_hrs: dayHrs?.mx_hrs || 0, mx_mins: dayHrs?.mx_mins || 0 };
        addCol[rIndex].mx_actionedBy[cIndex]['rd_level'] = rd_level?.value;
        addCol[rIndex].mx_actionedBy[cIndex]['rd_action'] = rd_action?.value;
        mxConfig['mx_config'] = addCol;
        resetIndex();
        resetData();
    }

    function addObj() {
        let list = [];
        const add = { rd_action: '', rd_level: '', mx_ola: {} };
        const obj = cloneDeep(addCol[0].mx_actionedBy);
        filter(obj, ele => { ele.rd_action = ''; ele.rd_level = ''; ele.mx_ola = {} });
        list = filter(obj, elm => Object.assign({ mx_actionedBy: elm.mx_actionedBy, ...add }));
        return list;
    }

    const handleAddRole = () => {
        removeColMatrix();
        if (selectValue === 'row') {
            const { mx_reqBy } = actionLevel;
            const reqObj = { mx_reqBy: mx_reqBy?.value, mx_sla: { mx_days: dayHrs?.mx_days || 0, mx_hrs: dayHrs?.mx_hrs || 0, mx_mins: dayHrs?.mx_mins || 0 }, mx_actionedBy: isEmpty(addCol) ? [] : addObj() };
            setaddCol(arr => [...arr, cloneDeep(reqObj)]);
            mxConfig['mx_config'] = addCol;
            handleMxConfig([...addCol, cloneDeep(reqObj)]);
            const list = rowRoles?.filter(ele => ele.label !== reqObj?.mx_reqBy);
            setRowRoles(list);
        } else {
            const { mx_actionedBy } = actionLevel;
            const reqObj = { mx_actionedBy: mx_actionedBy.value, rd_action: '', rd_level: '', mx_ola: {} };
            map(addCol, (el) => {
                el?.mx_actionedBy?.push(cloneDeep(reqObj));
            });
            mxConfig['mx_config'] = addCol;
            const list = colRoles?.filter(ele => ele.label !== reqObj?.mx_actionedBy);
            setColRoles(list);
        }
        resetData();
    }

    function resetIndex() {
        setColumnIndex(-1);
        setRowIndex(-1);
    }

    function resetData() {
        setSelectValue('');
        setActionLevel({});
        setDayHrs({});
        setEditingIndex(-1);
    }

    const handleCancle = () => {
        resetIndex();
        resetData();
        removeColMatrix();
    }

    const addSelectValue = (type) => {
        type === 'head' ? showAddPopup() : addColMatrix();
        setSelectValue(type);
        setIsEditing(false);
    }

    const editSelectedValue = (type, ind, data) => {
        setEditingIndex(ind);
        setIsEditing(true);
        setSelectValue(type);
        setDayHrs(data.mx_sla);
        const obj = roles?.find(ele => ele.label === data.mx_reqBy);
        setActionLevel({ mx_reqBy: obj });
    }

    const deleteSelectedValue = (type, ind, data) => {
        if (type === 'row') {
            addCol?.splice(ind, 1);
            mxConfig['mx_config'] = addCol;
        } else {
            addCol?.filter(ele => ele?.mx_actionedBy?.splice(ind, 1));
            mxConfig['mx_config'] = addCol;
        }
        resetIndex();
        resetData();
    }

    const handleEditRole = () => {
        removeColMatrix();
        const { mx_reqBy } = actionLevel;
        const reqObj = { mx_reqBy: mx_reqBy?.value, mx_sla: dayHrs, mx_actionedBy: isEmpty(addCol) ? [] : addObj() };
        addCol[editingIndex].mx_sla = reqObj?.mx_sla;
        mxConfig['mx_config'] = addCol;
        resetData();
    }

    function addColMatrix() {
        const element = document.getElementById("matrix-id");
        element.classList.add("matrix__overhide");
        setMoveCol(true);
    }

    function removeColMatrix() {
        const element = document.getElementById("matrix-id");
        element.classList.remove("matrix__overhide");
        setMoveCol(false);
    }

    function showAddPopup() {
        const id = document.getElementById('add-header');
        const elmt = document.getElementById('matrix-container');
        addColMatrix();
        elmt.scrollTo({ top: 0, left: id.getBoundingClientRect().left, behavior: 'smooth' });
    }

    function showCardPopup(pos) {
        const elmt = document.getElementById('matrix-container');
        addColMatrix();
        elmt.scrollTo({ top: 0, left: pos, behavior: 'smooth' });
    }

    const colListBox = map(addCol, (el, i) => {
        return <li key={i} id={i}>
            {map(el.mx_actionedBy, (elm, index) =>
                <div className="matrix__card" key={index}>
                    {(isEmpty(elm.rd_level) && isEmpty(elm.rd_action) && isEmpty(elm.mx_ola)) && <div className="matrix__cardadd" id={`add-card-${index}`} onClick={(e) => handleAddCard(i, index, e)}><span>Add</span></div>}
                    {(columnIndex === index && rowIndex === i) && <PopupCard>
                        <>
                            <Selectmenu placeholder="Please select level" value={actionLevel.rd_level} options={level?.rd_levels} onChange={(e) => handleDrop('rd_level', e)} />
                            <Selectmenu placeholder="Please select action" value={actionLevel.rd_action} options={actions?.rd_actions} onChange={(e) => handleDrop('rd_action', e)} />
                            <div className="matrix__textrow">
                                <Textbox placeholder="Day" type="number" name="mx_days" onChange={handleDayHrs} value={dayHrs?.mx_days} />
                                <Textbox placeholder="Hrs" type="number" name="mx_hrs" onChange={handleDayHrs} value={dayHrs?.mx_hrs} />
                                <Textbox placeholder="Min" type="number" name="mx_mins" onChange={handleDayHrs} value={dayHrs?.mx_mins} />
                            </div>
                            <div className="matrix__button">
                                <Button compact ghost label="Cancel" handleOnSubmit={handleCancle} />
                                <Button compact primary label="Submit" disabled={!actionLevel.rd_level || !actionLevel.rd_action} handleOnSubmit={() => handleIndexCard(i, index)} />
                            </div>
                        </>
                    </PopupCard>}
                    <div className="matrix__content">
                        <p>Level {elm.rd_level} - {elm.rd_action?.replace('_', ' ').substring(0, 18)}</p>
                        <span>{elm?.mx_ola?.mx_days ? elm?.mx_ola?.mx_days : 0} Day {elm?.mx_ola?.mx_hrs ? elm?.mx_ola?.mx_hrs : 0} hrs {elm?.mx_ola?.mx_mins ? elm?.mx_ola?.mx_mins : 0} mins</span>
                    </div>
                    {(Number.isInteger(elm.rd_level) && !isEmpty(elm.rd_action)) && dockerState !== 'view' && <div className="edit-delete">
                        <span onClick={(e) => handleEditCard(i, index, elm, e)}> <img src={Images.editIcon} alt="edit" /> </span>
                        <span onClick={() => handleDeleteCard(i, index, elm)}>  <img src={Images.deleteIcon} alt="delete" /> </span>
                    </div>}
                </div>)}
        </li>
    });

    return (
        <>
            <ContentWrapper>
                <div id="matrix-container" className="matrix">
                    <div className={classNames({ "matrix__header": true, "fixed": moveCol })}>
                        <h4>Configuration</h4>
                        <span onClick={handleExpandMatrix}><img src={!expand ? Images.expand2 : Images.minimize} alt="expand" /></span>
                    </div>
                    <div className="matrix__table">
                        <div className="matrix__label">
                            <h5>Actioned by</h5>
                            <h5>Requested by</h5>
                        </div>
                        <div id="matrix-id" className="matrix__sheild">
                            <div className="matrix__head" style={{ 'width': addCol[0]?.mx_actionedBy?.length >= 5 && `calc(185px * ${addCol[0]?.mx_actionedBy?.length} + 185px)` }}>
                                <ul>
                                    {!isEmpty(addCol) && map(addCol[0].mx_actionedBy, (el, i) => {
                                        return <li key={i}>{el.mx_actionedBy?.substring(0, 15)?.replace('_', ' ')?.toLowerCase()?.replace(/(^\w|\s\w)/g, m => m.toUpperCase()) + (el.mx_actionedBy?.length > 15 ? '...' : '')}
                                            <span className="delete" onClick={() => deleteSelectedValue('col', i, el)}><img src={Images.deleteIcon} alt="delete" /></span>
                                        </li>
                                    })}
                                </ul>
                                {addCol.length > 0 && <div className="matrix__add">
                                    {dockerState !== 'view' && <span id="add-header" onClick={() => addSelectValue('head')}>+ Select Role</span>}
                                    {selectValue === 'head' && <PopupCard left={addCol[0]?.mx_actionedBy?.length >= 4 ? true : false}>
                                        <>
                                            <Selectmenu placeholder="Please select role" isDisabled={isEditing} value={actionLevel.mx_actionedBy} options={colRoles} onChange={(e) => handleDrop('mx_actionedBy', e)} />
                                            {/* <div className="matrix__textrow">
                                                <Textbox placeholder="Day" type="number" name="mx_days" onChange={handleDayHrs} value={dayHrs.mx_days} />
                                                <Textbox placeholder="Hrs" type="number" name="mx_hrs" onChange={handleDayHrs} value={dayHrs.mx_hrs} />
                                                <Textbox placeholder="Min" type="number" name="mx_mins" onChange={handleDayHrs} value={dayHrs.mx_mins} />
                                            </div> */}
                                            <div className="matrix__button">
                                                <Button compact ghost label="Cancel" handleOnSubmit={handleCancle} />
                                                <Button compact primary label="Submit" disabled={!actionLevel.mx_actionedBy} handleOnSubmit={() => isEditing ? handleEditRole() : handleAddRole(addCol.length - 1)} />
                                            </div>
                                        </>
                                    </PopupCard>}
                                </div>}
                            </div>
                            <div className="matrix__list">
                                <ul style={{ 'width': addCol[0]?.mx_actionedBy?.length >= 5 && `calc(185px * ${addCol[0]?.mx_actionedBy?.length} + 185px)` }}>
                                    {colListBox}
                                </ul>
                            </div>
                        </div>
                        <div className={classNames({ 'matrix__labelcol': true })} style={{ marginTop: addCol.length > 0 && `calc(-65px * ${addCol.length})` }}>
                            <ul>
                                {map(addCol, (el, i) => {
                                    return <li key={i}>
                                        <span>{el.mx_reqBy?.substring(0, 12)?.replace('_', ' ')?.toLowerCase()?.replace(/(^\w|\s\w)/g, m => m.toUpperCase()) + (el.mx_reqBy?.length > 12 ? '...' : '')}</span><span onClick={() => editSelectedValue('row', i, el)}><img src={Images.arrowDown} alt="edit" /></span>
                                        <span className="delete" onClick={() => deleteSelectedValue('row', i, el)}><img src={Images.deleteIcon} alt="delete" /></span>
                                        <p>{el?.mx_sla?.mx_days ? el?.mx_sla?.mx_days : 0} Day {el?.mx_sla?.mx_hrs ? el?.mx_sla?.mx_hrs : 0} hrs {el?.mx_sla?.mx_mins ? el?.mx_sla?.mx_mins : 0} mins</p>
                                    </li>
                                })}
                            </ul>
                            <div className="matrix__add">
                                {dockerState !== 'view' && <span onClick={() => addSelectValue('row')}>+ Select Role</span>}
                                {selectValue === 'row' && <PopupCard top={addCol.length >= 2 ? true : false}>
                                    <>
                                        <Selectmenu placeholder="Please select role" isDisabled={isEditing} value={actionLevel.mx_reqBy} options={rowRoles} onChange={(e) => handleDrop('mx_reqBy', e)} />
                                        <div className="matrix__textrow">
                                            <Textbox placeholder="Day" disabled={dockerState === 'view'} type="number" name="mx_days" onChange={handleDayHrs} value={dayHrs?.mx_days} />
                                            <Textbox placeholder="Hrs" disabled={dockerState === 'view'} type="number" name="mx_hrs" onChange={handleDayHrs} value={dayHrs?.mx_hrs} />
                                            <Textbox placeholder="Min" disabled={dockerState === 'view'} type="number" name="mx_mins" onChange={handleDayHrs} value={dayHrs?.mx_mins} />
                                        </div>
                                        <div className="matrix__button">
                                            <Button compact ghost label="Cancel" handleOnSubmit={handleCancle} />
                                            <Button compact primary label="Submit" disabled={!actionLevel.mx_reqBy} handleOnSubmit={() => isEditing ? handleEditRole() : handleAddRole(addCol.length)} />
                                        </div>
                                    </>
                                </PopupCard>}
                            </div>
                        </div>
                    </div>
                </div>
            </ContentWrapper>
        </>
    )
}