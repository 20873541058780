import PropTypes from 'prop-types';
import { Button } from '..';

import './style.scss';

export default function ButtonRow(props) {

    const { label, label1, disableSave, handleButtonClick } = props;

    return (
        <>
            <div className="button-row">
                <div className="button-row--button">
                    <Button primary compact transparent label={label} handleOnSubmit={() => handleButtonClick(label)} />
                </div>
                <Button primary compact label={label1} disabled={disableSave} handleOnSubmit={() => handleButtonClick(label1)} />
            </div>

        </>
    )
}


ButtonRow.propTypes = {
    label: PropTypes.string,
    label1: PropTypes.string,
    handleButtonClick: PropTypes.func
}

ButtonRow.defaultProps = {
    label: '',
    label1: '',
    handleButtonClick: () => { }
}


