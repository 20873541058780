import { map } from 'lodash';
import PropTypes, { array } from 'prop-types';

import './style.scss';

export default function Docker(props) {

    const { dockerlist, dockerClick, multiSelect, selectlist } = props;

    const list = map(dockerlist, (el, i) => {
        return <li className={`${selectlist.length === 1 || multiSelect ? 'docker--active' : 'docker--disable'}`} key={i}><img onClick={() => dockerClick(selectlist, el.key)} src={el.src} alt={el.key} /></li>
    })

    return (
        <>
            <div className="docker">
                <div className="docker__list">{selectlist.length} Selected</div>
                <ul>
                    {list}
                </ul>
                <div className="docker__list" onClick={() => dockerClick(selectlist, 'cancel')}><span>Cancel</span></div>
            </div>
        </>
    )
}

Docker.propTypes = {
    dockerlist: array.isRequired,
    selectlist: array.isRequired,
    dockerClick: PropTypes.func
}

Docker.defaultProps = {
    dockerlist: [],
    selectlist: [],
    dockerClick: () => { }
}



