import PropTypes from 'prop-types';
import classNames from 'classnames';

import './style.scss';

export default function ContentWrapper(props) {

    const { transparent, shadow, last, children, manual } = props;

    return (
        <>
            <div className={classNames({ 'content': !manual, 'content--transparent': transparent, 'content--shadow': shadow, 'content--last': last })}>
                {children}
            </div>
        </>
    )
}

ContentWrapper.propTypes = {
    transparent: PropTypes.bool,
    shadow: PropTypes.bool,
    last: PropTypes.bool,
}

ContentWrapper.defaultProps = {
    transparent: false,
    shadow: false,
    last: false
}


