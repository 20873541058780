import { useContext, useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import { WorkFlowContext } from 'providers';
import { ContentWrapper, Textbox, Textarea, Selectmenu, DatePickers } from 'components/generic';

export const GeneralDetails = (props) => {
    const { doesExist, handleInput, handleDrop, handleDatepicker, appList, eventList } = props;
    const { createObj, dockerState } = useContext(WorkFlowContext);
    const [setvalue] = createObj;
    const [position, setPosition] = useState('auto');

    let today = new Date();

    useEffect(() => {
        const pos = window.innerHeight > 800 ? 'bottom' : 'top';
        setPosition(pos)
    }, [dockerState])

    // handle selected
    const getSelected = (list, id) => {
        return list?.filter(ele => ele.value === id);
    }

    return (
        <>
            <ContentWrapper>
                <div className="row">
                    <div className="col-4">
                        <Textbox label='Workflow Name' maxlength={30} mandatory={true} value={setvalue?.wf_name || ''} placeholder="Eg: Litigation approval" name="wf_name" onChange={handleInput} disabled={dockerState === 'view' || dockerState === 'edit'} error={!isEmpty(doesExist?.data) && (dockerState === 'add' || dockerState === 'copy')} errorMessage={doesExist?.message} />
                        <Textarea label='Workflow Description' maxlength={50} value={setvalue?.wf_description || ''} name="wf_description" onChange={handleInput} placeholder="Eg: Workflow for litigation approval" disabled={dockerState === 'view'} />
                    </div>
                </div>
            </ContentWrapper>

            <ContentWrapper last>
                <h3>Application/Event Details</h3>
                <div className="row">
                    <div className="col-4">
                        <Selectmenu label='Application' isSearchable={true} position={position} mandatory={true} options={appList} onChange={(e) => handleDrop('sa_id', e.value)} value={getSelected(appList, setvalue?.sa_id)} isDisabled={dockerState === 'view' || dockerState === 'edit'} />
                    </div>
                    <div className="col-4">
                        <Selectmenu label='Event' isSearchable={true} position={position} mandatory={true} options={eventList} isDisabled={setvalue.length === 0 || setvalue?.sa_id === '' || dockerState === 'view' || dockerState === 'edit'} onChange={(e) => handleDrop('et_code', e.value)} value={getSelected(eventList, setvalue.et_code)} event={true} />
                    </div>
                </div>
                <div className="row">
                    <div className="col-4">
                        <DatePickers label='Start Date' mandatory={true} minDate={dockerState === 'add' ? today : setvalue?.wf_startDate} value={setvalue?.wf_startDate} onChange={(e) => handleDatepicker('wf_startDate', e)} isDisabled={dockerState === 'view'} />
                    </div>
                    <div className="col-4">
                        <DatePickers label='End Date' value={setvalue?.wf_endDate} minDate={setvalue?.wf_startDate} onChange={(e) => handleDatepicker('wf_endDate', e)} isDisabled={dockerState === 'view'} />
                    </div>
                </div>
            </ContentWrapper>
        </>
    )
}