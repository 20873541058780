
import { useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import * as api from 'actions';
import { useInView } from 'react-intersection-observer';
import { map, isEmpty, startCase } from 'lodash';
import { MatrixContext } from 'providers';
import { matrixCreateObj } from 'interface';
import { headerTab, listRequestObj, matrixTab, dockerList, popup, matrixSearchList } from 'config';
import { TitleSection, CardRow, CardComponent, BreadCrumb, BottomSection, TabList, PopupConfimation, Docker, Loader, Matrix, MatrixDetails, Toaster, Popup, ListCard, ButtonRow, NoRecord } from 'components/generic';
import { Images } from 'assets/Images';
import './styles.scss';

export default function MatrixMaintenance() {

    const [resObj, setResponse] = useState(listRequestObj);
    const [matrixList, setMatrixList] = useState([]);
    const [roles, setRoles] = useState([]);
    const [appList, setAppList] = useState([]);
    const [appName, setAppName] = useState('');
    const [activeTab, setTab] = useState(headerTab[0].key);
    const [activeState, setActiveState] = useState('dashboard');
    const [breadCrumb, setbreadCrumb] = useState(['Matrix']);
    const [selectlist, setSelectList] = useState([]);
    const [showDocker, setDocker] = useState(false);
    const [createObj, setCreateObj] = useState(matrixCreateObj);
    const [showPopup, setShowPopup] = useState(false);
    const [dockerState, setDockerState] = useState('');
    const [creatState, setCreateState] = useState(true);
    const [bottonState, setBottonState] = useState('');
    const [searchInput, setSearchInput] = useState('');
    const [searchValue, setSearchValue] = useState('');
    const [disableSave, setDisabledSave] = useState(true);
    const [expand, setExpand] = useState(false);
    const [showCheckPopup, setShowCheckPopup] = useState(false);
    const [attachedWorkflows, setAttachedWorkflows] = useState([]);

    const [showDrop, setShowDrop] = useState(false);

    const [ref, inView] = useInView();
    const [startIndex, setStartIndex] = useState(0);
    const [totalList, setTotalList] = useState(0);

    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const [permission, setPermission] = useState([]);
    const [dockerData, setDockerData] = useState(dockerList);
    const [norecord, setNorecord] = useState(false);

    let selectedCard = [];
    let statusObj = {};
    let selectedWorkflow = [];

    const loadPermission = () => {
        const allPermissions = api.decrypting(localStorage.getItem('_ro'));
        const parsedPermission = allPermissions ? JSON?.parse(allPermissions) : {};
        const dockerPermission = parsedPermission?.matrix_maintenance?.map(el => el?.replace(/_.*/, '')?.toLowerCase());
        const docker = dockerList.filter(el => dockerPermission?.includes(el.key));
        setDockerData(docker);
        setPermission(parsedPermission.matrix_maintenance);
    };

    // get marix list
    const { refetch: listRefetch, isLoading } = useQuery(['getMatrixList', resObj], () => api.getListing('/matrix/list', resObj), {
        refetchOnWindowFocus: false, enabled: creatState,
        onSuccess: (data) => {
            resObj?.startIndex === 0 && setStartIndex(0);
            if (data?.data?.data?.length > 0) {
                resObj?.startIndex === 0 ? setMatrixList(data?.data?.data[0]?.result) : setMatrixList(state => ([...new Set([...state, ...data?.data?.data[0]?.result || []])]));
                setTotalList(data?.data?.data[0]?.total);
                applicationRefetch();
            } else {
                setMatrixList([]);
                setTotalList(0);
            }
        }
    });
    // get roles
    const { refetch: roleRefetch } = useQuery('getRoles', () => api.get(`roles/list?sa_id=${appName}`), {
        enabled: false, onSuccess: (res) => {
            const a = map(res?.data?.data?.result, el => ({ value: el?.name, label: startCase(el.name?.toLowerCase()) }));
            setRoles(a);
        }
    });
    // get application
    const { data: applications, refetch: applicationRefetch } = useQuery('getApplication', () => api.get('application/list'), {
        enabled: true,
    });

    // doesexist for matrix name
    const { data: doesExist, refetch: doesExistRefetch } = useQuery(['doesExistMatrix', createObj.mx_name], () => api.get(`/matrix/${createObj.mx_name}`), {
        enabled: false,
    });

    // get matrix by code
    const { refetch: getMatrixById } = useQuery(['getMatrixById', selectedCard], () => api.get(`matrix/${selectedCard[0].mx_code}/${selectedCard[0].mx_version}`), {
        enabled: false,
        onSuccess: (res) => {
            const data = res?.data?.data[0];
            const obj = {
                mx_code: data?.mx_code, mx_config: data?.mx_config, mx_name: data?.mx_name, mx_description: data?.mx_description, mx_mode: data?.mx_mode,
                mx_status: data?.mx_status, mx_version: data?.mx_version, sa_id: data?.sa_id, wf_details: []
            };
            formCreateObject(obj);
            setTimeout(() => roleRefetch(), 200);
        }
    });

    // add Matrix
    const { refetch: addRefetch } = useQuery(['addMatrix', createObj], () => api.create('matrix/add', createObj), {
        enabled: false, onSuccess: (data) => {
            data?.data?.code === 200 ? setError(false) : setError(true);
            setErrorMessage(data?.data?.message);
            setCreateState(false);
            data?.data?.code === 200 && reset();
        }
    });

    // edit matrix
    const { refetch: editMatrix } = useQuery(['editMatrix', createObj], () => api.update(`matrix/${createObj.mx_code}/${createObj.mx_version}`, createObj), {
        enabled: false,
        onSuccess: (data) => {
            data?.data?.code === 200 ? setError(false) : setError(true);
            setErrorMessage(data?.data?.message);
            setCreateState(false);
            data?.data?.code === 200 && reset();
        }
    });

    // disable enable workflow
    const { refetch: getStatus } = useQuery(['disable', statusObj], () => api.status(`/matrix/disable/${statusObj.mx_code}/${statusObj.mx_version}`, statusObj), {
        enabled: false,
        onSuccess: (data) => {
            data?.data?.code === 200 ? setError(false) : setError(true);
            setErrorMessage(data?.data?.message);
            listRefetch();
        }
    });

    const { refetch: refetchAttachedWF } = useQuery(['', createObj], () => api.get(`matrix/${createObj?.mx_code}/${createObj?.mx_version}`), {
        enabled: false, onSuccess: (res) => {
            const list = res?.data?.data[0]?.attachedWorkflow?.map(ele => ({ id: ele?.wf_name, wf_name: ele?.wf_name, wf_code: ele?.wf_code, wf_version: ele?.wf_version }));
            if (list?.length > 0) {
                setAttachedWorkflows(list);
                setShowCheckPopup(true);
            } else {
                editMatrix();
            }
        }
    });

    useEffect(() => {
        if (inView) {
            if (startIndex < totalList - 20) {
                setStartIndex(startIndex + 20);
                setResponse(state => ({ ...state, startIndex: startIndex + 20 }), []);
            } else {
                return false;
            }
        } else {
            return false;
        }
    }, [inView]);

    useEffect(() => {
        const a = applications?.data?.data?.map(el => ({ value: el.sa_id, label: el.sa_name }));
        setAppList(a);
    }, [applications]);

    useEffect(() => {
        !isEmpty(createObj.mx_name) && doesExistRefetch();
    }, [createObj.mx_name]);

    useEffect(() => {
        errorMessage && setTimeout(() => setErrorMessage(''), 2000)
    }, [errorMessage]);

    useEffect(() => {
        const dis = !(createObj?.sa_id?.length > 0 && createObj?.mx_name?.length > 0 && createObj?.mx_config?.length > 0);
        setDisabledSave(dis);
    }, [createObj]);

    useEffect(() => {
        loadPermission();
        setTimeout(() => setNorecord(true), 500)
    }, []);

    // Add new Matrix
    const handleAdd = (e) => {
        setShowDrop(false);
        setActiveState('general');
        setDockerState('add');
        setbreadCrumb([...breadCrumb, 'General Details']);
        applicationRefetch();
    }

    const handleTabClick = (e) => {
        setShowDrop(false);
        setTab(e.key);
        setResponse(state => ({ ...state, startIndex: 0, sort: [{ id: e.key, value: -1 }] }), []);
        setTimeout(() => listRefetch(), 200);
    }

    const handleSearch = (e) => {
        setShowDrop(false);
        const filter = e ? [e.obj] : [];
        e ? setSearchValue(e.value) : setSearchValue('');
        e ? setSearchInput(e.input) : setSearchInput('');
        setResponse(state => ({ ...state, filter: filter, startIndex: 0 }), []) && listRefetch();
    }

    const handleChecked = (e, list) => {
        setShowDrop(false);
        if (e) {
            setDocker(true);
            setSelectList([list]);
        } else {
            setDocker(false);
            setSelectList([]);
        }
    }

    const handleAddTab = (e) => {
        if (createObj?.sa_id) {
            const bread = [...breadCrumb];
            const state = ['add', 'copy'].includes(dockerState) ? 'Add' : dockerState === 'edit' ? 'Edit' : 'View'
            bread[1] = e.key === 'general' ? e.value : `${state} ${e.value}`;
            setbreadCrumb(bread);
            setActiveState(e.key);
        } else {
            alert('Please select an application');
        }
    }

    const handleToggle = (e, list) => {
        setShowDrop(false);
        const resObj = {
            mx_code: list.mx_code,
            mx_version: list.mx_version,
            mx_status: e ? 'A' : 'I'
        };
        statusObj = resObj;
        getStatus();
    }

    const handleBottomSection = (e) => {
        setBottonState(e);
        setShowPopup(true);
    }

    const dockerClick = (e, state) => {
        applicationRefetch();
        selectedCard = e;
        setDocker(false);
        setDockerState(state);
        setSelectList([]);
        setbreadCrumb([...breadCrumb, 'General Details']);
        ['edit', 'copy', 'view'].includes(state) && getMatrixById();
    }

    const handleConfimation = (e) => {
        setShowPopup(false);
        (e === 'Yes' && bottonState === 'Goback') && reset();
        (e === 'Yes' && bottonState === 'Save') && createMatrix();
    }

    const handleInput = ({ target: { name, value } }) => {
        setCreateObj(state => ({ ...state, [name]: value }), []);
    }

    const handleDrop = (name, value) => {
        setAppName(value.value);
        setCreateObj(state => ({ ...state, [name]: value.value }), []);
        name === 'sa_id' && value.label && value.label && setTimeout(() => roleRefetch(), 200);
    }

    const createMatrix = () => {
        // const obj = createObj.mx_config.map(({ mx_actionedBy, ...rest }) => {
        //     return {
        //         ...rest,
        //         mx_actionedBy: mx_actionedBy.filter(elm => elm.rd_action !== '')
        //     }
        // });
        // createObj.mx_config = obj;
        if (dockerState === 'add' || dockerState === 'copy') {
            addRefetch();
        } else {
            createObj?.mx_mode === 'PUBLISHED' ? refetchAttachedWF() : editMatrix();
        }
    }

    function formCreateObject(obj) {
        setAppName(obj?.sa_id);
        obj.mx_name = dockerState !== 'copy' ? obj.mx_name : '';;
        setCreateObj(obj);
        setActiveState('general');
    }

    // handle toaster
    const handleToaster = (e) => {
        e === 'close' && setErrorMessage('');
    }

    // handle save status
    const handleStatus = (e, type) => {
        setCreateObj(state => ({ ...state, mx_mode: type }), []);
    }

    const handleMxConfig = (e) => {
        setCreateObj(state => ({ ...state, mx_config: e }), []);
    }

    const handleExpandMatrix = () => {
        setExpand(!expand);
    }

    const handlePopupChecked = (e) => {
        selectedWorkflow = e;
    }

    const handleSelectList = (e) => { }

    const handleCheckPopupSubmit = (e) => {
        (e === 'Save') && setCreateObj(state => ({ ...state, wf_details: selectedWorkflow }), []);
        (e === 'Goback') && setAttachedWorkflows([]);
        (e === 'Save') && setTimeout(() => editMatrix(), 200);
        setShowCheckPopup(false);
    }

    const handleTitleDrop = (val) => {
        setShowDrop(val);
    }

    const handleTitleSelectedDrop = (val, key) => {
        setShowDrop(false);
        const filter = [{ id: key, value: val }]
        setTab(key);
        setResponse(state => ({ ...state, filter: filter, startIndex: 0 }), []);
    }

    const reset = () => {
        // setResponse(listRequestObj);
        setActiveState('dashboard');
        setCreateObj(matrixCreateObj);
        setbreadCrumb(['Matrix']);
        listRefetch();
        setShowCheckPopup(false);
        selectedWorkflow = [];
    }

    if (isLoading) {
        if (startIndex === 0) {
            return <Loader loading={isLoading} />
        }
    }

    const cardList = matrixList?.length > 0 && map(matrixList, (el, i) => {
        return <CardComponent key={i} list={el} checked={selectlist.length > 0 && (selectlist[0]?._id === el._id)} handleToggle={handleToggle} handleChecked={handleChecked} checkbox toggle toggleOn={el.mx_status === 'A' ? true : false} />
    })

    return (
        <>
            {activeState === 'dashboard' &&
                <>
                    <TitleSection title="Matrix" count={totalList} headerList={headerTab} tab search add={permission?.includes('ADD_MATRIX')} activeTab={activeTab} handleTabClick={handleTabClick} handleAdd={handleAdd} handleSearch={handleSearch} searchList={matrixSearchList} cardList={matrixList} searchValue={searchValue} searchInput={searchInput} appList={appList} showDrop={showDrop} handleTitleDrop={handleTitleDrop} handleTitleSelectedDrop={handleTitleSelectedDrop} application={true} />
                    <CardRow>{cardList}</CardRow>
                    {matrixList?.length === 0 && norecord && <NoRecord></NoRecord>}
                    <span ref={ref} style={{ visibility: 'hidden' }}>Load More</span>
                </>
            }

            {(activeState === 'general' || activeState === 'matrix') &&
                <div className="layout-wrap--bottom">
                    {!expand && <><BreadCrumb path={breadCrumb} />
                        <TabList tabList={matrixTab} tabActive={activeState} handleTabClick={handleAddTab} /></>}
                    <MatrixContext.Provider value={{ createObj: [createObj, setCreateObj], appList, dockerState }}>
                        {activeState === 'general' && <MatrixDetails doesExist={doesExist?.data} handleInput={handleInput} handleDrop={handleDrop} manual={false} />}
                        {activeState === 'matrix' && <Matrix roles={roles} expand={expand} handleExpandMatrix={handleExpandMatrix} handleMxConfig={handleMxConfig} />}
                    </MatrixContext.Provider>
                    <BottomSection disableSave={disableSave || dockerState === 'view'} handleButtonClick={handleBottomSection} />
                </div>
            }

            {showDocker && <Docker dockerlist={dockerData} selectlist={selectlist} dockerClick={dockerClick} />}

            {errorMessage && <Toaster success={!error} error={error} header={error ? 'Oops!!! something went wrong' : 'Yay! Everything Worked!'} content={typeof (errorMessage) === 'string' ? errorMessage : ''} handleUndoClose={handleToaster} />}

            {showPopup && <PopupConfimation showstatus={bottonState === 'Save'} activeStatus={createObj?.mx_mode} heading="Warning" handleChecked={handleStatus} content={bottonState === 'Goback' ? popup.back : dockerState === 'add' ? popup.save : dockerState === 'edit' ? popup.update : ''} handleConfimation={handleConfimation} />}

            {showCheckPopup && <Popup small alignleft>
                <div className='popup-wrapper'>
                    <img src={Images.warning} alt="warning" />
                    <h5>The <span>"{createObj.mx_name}"</span> has been linked to the workflows below. To overwrite the version, please select the workflow</h5>
                </div>
                <ListCard list={attachedWorkflows} checkBox={true} handleChecked={handlePopupChecked} handleSelectList={handleSelectList} />
                <div className='buttons'><ButtonRow label="Goback" label1="Save" handleButtonClick={handleCheckPopupSubmit} /></div>
            </Popup>}
        </>
    )

}