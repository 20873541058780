import { Link, useHistory, withRouter } from 'react-router-dom';
import { useQuery } from 'react-query';
import * as api from 'actions';
import { Images } from 'assets/Images';
import './style.scss';

const SideBar = () => {
    const role = api.decrypting(localStorage.getItem('_ro'));
    const permission = role ? JSON?.parse(role) : {};
    const history = useHistory();

    const { refetch: refetchLogout } = useQuery(['logout', ''], () => api.logout(), {
        enabled: false,
        onSuccess: () => {
            localStorage.clear();
            history.push({ pathname: '/' });
        }
    });

    // logout API call
    const handleLogout = () => {
        refetchLogout();
    }

    return (
        <>
            <aside className="side-bar">
                <div className="logo"> <img src={Images.logo} alt="approval" /></div>
                <nav>
                    <ul>
                        <li>
                            {permission?.hasOwnProperty('workflow_maintenance') && <Link to={{ pathname: "/workflow-maintenance" }}><img src={Images.workflow} alt='workflow' />
                                <span>Workflow</span>
                            </Link>}
                        </li>
                        <li>
                            <Link to={{ pathname: "/exception-maintenance" }} ><img src={Images.condition} alt='exception' />
                                <span>Exception</span>
                            </Link>
                        </li>
                        <li>
                            <Link to={{ pathname: "/matrix-maintenance" }} ><img src={Images.matrix} alt='matrix' />
                                <span>Matrix</span>
                            </Link>
                        </li>
                        <li onClick={handleLogout}>
                            <img src={Images.logout} alt='logout' />
                            <span>Logout</span>
                        </li>
                    </ul>
                </nav>
            </aside>
        </>

    )
}

export default withRouter(SideBar);

