import { CreatePopup, Selectmenu, Textbox, Textarea } from 'components/generic';

export default function ConditionalDetails(props) {

    const { createObj, disbleCondition, dockerState, manualFlow, conditionExists, appList, eventList, severityList, addConditionSelect, addConditionInput, handleCreatePopup } = props;

    // handle selected
    const getSelected = (list, id) => {
        return list?.filter(ele => ele.value === id);
    }

    return (
        <>
            <CreatePopup disableSave={disbleCondition} handleCreatePopup={handleCreatePopup}>
                <h3>Add Exception</h3>
                <Selectmenu label='Application' isSearchable={true} isDisabled={manualFlow || dockerState === 'copy'} options={appList} mandatory value={getSelected(appList, createObj.sa_id)} onChange={(e) => addConditionSelect(e, 'sa_id')} />
                <Selectmenu label='Event' isSearchable={true} isDisabled={createObj?.sa_id?.length === 0 || manualFlow || dockerState === 'copy'} options={eventList} mandatory value={getSelected(eventList, createObj.et_code)} onChange={(e) => addConditionSelect(e, 'et_code')} event={true} />
                <Selectmenu label='Severity' options={severityList} mandatory value={getSelected(severityList, createObj.cn_severity)} onChange={(e) => addConditionSelect(e, 'cn_severity')} />
                <Textbox label='Exception Name' maxlength={30} mandatory placeholder="Eg: Approval condition" value={createObj.cn_name} error={conditionExists} errorMessage={'Exception already exists.'} onChange={(e) => addConditionInput(e, 'cn_name')} />
                <Textarea label='Exception Description' maxlength={50} placeholder="Eg: Exceptions for Freeze Interest" value={createObj.cn_description} onChange={(e) => addConditionInput(e, 'cn_description')} />
            </CreatePopup>
        </>
    )
}