import { Images } from 'assets/Images';
import './styles.scss';

export default function RuleTemplate(props) {

  const { operSelect, viewOnly } = props;

  return (
    <div className="rule-template hide">
      <input type="text" className="drag-box" disabled={viewOnly} placeholder="Drag fact" />
      <select className="dropdown operator" disabled={viewOnly} defaultValue="default">
        <option hidden value="default" disabled>Select Operator</option>
        {operSelect?.map((ele, index) => (<option key={index}>{ele}</option>))}
      </select>
      <div className="value-box" >
        <input className="value" disabled={viewOnly} type="text" placeholder="Value" />
        {!viewOnly && <img src={Images.closeIcon} alt="close" />}
      </div>
      <button className="remove-rule" disabled={viewOnly}><img src={Images.deleteIcon} alt="delete"  /></button>
      <button className="drag-container" disabled={viewOnly}>{!viewOnly && <img src={Images.drag} alt="drag" className="drag-handler" />}
      </button>
    </div>
  );
}