import { array } from 'prop-types';
import { map } from 'lodash';

import './style.scss';

export default function BreadCrumb(props) {

    const { path } = props;

    const list = map(path, (el, i) => {
        return <li key={i}>{el}</li>
    })

    return (
        <>
            <ul className="bread-crumbs">{list}</ul>
        </>
    )
}

BreadCrumb.propTypes = {
    path: array
}

BreadCrumb.defaultProps = {
    path: []
}


