import { useEffect, useState } from 'react';
import { useLocation, useHistory, withRouter } from 'react-router-dom';
import { Toaster } from 'components/generic';
import { useQuery } from 'react-query';
import * as api from 'actions';
import { Images } from 'assets/Images'
import { map, isEmpty } from 'lodash';
import SimpleCrypto from "simple-crypto-js";

import './style.scss';

function LandingPage() {
    const { REACT_APP_LOGIN_URL, REACT_APP_REDIRECT_URL } = process.env;
    const location = useLocation();
    const history = useHistory();
    let loginObj = { username: '', password: '', code: '', redirect_uri: '', authorizationcode: 'true' };
    const [showToaster, setShowToaster] = useState(false);

    useEffect(() => {
        showToaster && setTimeout(() => setShowToaster(''), 3000)
    }, [showToaster]);


    const { refetch: refetchLogin } = useQuery(['login', loginObj], () => api.login(loginObj), {
        enabled: false,
        onSuccess: (data) => {
            localStorage.setItem('_ac', data?.access_token);
            localStorage.setItem('_rc', data?.refresh_token);
            localStorage.setItem('_sc', data?.session_state);
            refetchAuth();
        }
    });

    const { refetch: refetchLogout } = useQuery(['logout', ''], () => api.logout(), {
        enabled: false,
        onSuccess: () => {
            history.push({ pathname: '/missing-privilege' });
            localStorage.clear();
        }
    });

    // authenticate api
    const { refetch: refetchAuth } = useQuery(['getAuthenticate', ''], () => api.authenticate(), {
        enabled: false, onSuccess: (data) => {
            if (data?.data?.data?.attributes?.applications.includes('APPROVAL_PORTAL')) {
                const arr = map(data?.data?.data?.roles, el => el.permissions);
                const roles = arr,
                    keys = ['exception_maintenance', 'matrix_maintenance', 'workflow_maintenance'],
                    addToSet = (s, v) => s.add(v),
                    result = Object.fromEntries(keys.map(k => [
                        k,
                        [...roles.reduce((r, o) => (o[k] || []).reduce(addToSet, r), new Set)]
                    ]));
                const filtered = Object.keys(result)
                    .filter(key => Array.isArray(result[key]) && result[key].length != 0)
                    .reduce((acc, key) => { acc[key] = result[key]; return acc }, {});

                if (!isEmpty(filtered)) {
                    localStorage.setItem('_ro', encrypting(JSON.stringify(result)));
                    result?.hasOwnProperty('workflow_maintenance') ? history.push({ pathname: '/workflow-maintenance' }) : history.push({ pathname: '/exception-maintenance' });
                } else {
                    refetchLogout();
                }
            } else {
                refetchLogout();
            }

        }
    });

    const handleUrl = () => {
        window.location.href = REACT_APP_LOGIN_URL;
    }

    useEffect(() => {
        if (location.search.includes('?state')) {
            const params = new URLSearchParams(location.search);
            loginObj.code = params.get('code');
            loginObj.redirect_uri = REACT_APP_REDIRECT_URL;
            refetchLogin();
        }
    }, [location]);

    // encrypt data
    const encrypting = (data) => {
        const _sc = localStorage.getItem('_sc');
        const simpleCrypto = new SimpleCrypto(_sc);
        const encrypt = data ? simpleCrypto.encrypt(data) : '';
        return encrypt;
    }

    // handle toaster
    const handleToaster = (e) => {
        e === 'close' && setShowToaster(false);
    }

    return (
        <>
            <div className="landing-wrap">
                <div className="landing-wrap__container">
                    <div className="row landing-wrap__row">
                        <div className="col-4">
                            <h1>Enterprise <span>Digital Automation</span> Platform</h1>
                            <p>We built solutions based on these platform capabilities that cater to specific use cases. </p>
                            <button onClick={handleUrl}>Click here to login</button>
                        </div>
                        <div className="col-8">
                            <img src={Images.banner} alt='Banner' />
                        </div>
                    </div>
                </div>
            </div>
            {showToaster && <Toaster center top error={true} header='Oops!!! You dont have access' content='Please contact admin' handleUndoClose={handleToaster} />}
        </>
    )
}

export default withRouter(LandingPage);
