
export const workflowCreateObj = { wf_name: '', wf_description: '', wf_mode: 'PUBLISHED', et_code: '', et_name: '', sa_id: '', wf_startDate: '', wf_endDate: '', wf_details: [] };

export const matrixCreateObj = { mx_name: '', sa_id: '', mx_description: '', mx_status: 'A', mx_mode: 'PUBLISHED', mx_config: [] };

export const conditionCreateObj = { cn_name: '', cn_mode: 'PUBLISHED', sa_id: '', et_code: '', et_name: '', cn_status: 'A', cn_description: '', cn_severity:'', cn_rule: { all: [] } };

export const eventCreateObj = { et_name: '', et_code: '', et_description: '', sa_id: '', et_status: 'A' };

export const factCreateObj = { fa_key: '', sa_id: '', fa_name: '', fa_description: '', fa_type: [ 'DECISION' ], fa_formulaType: '', fa_example: '',  fa_formula : '', fa_fields : '', fa_allowedDataType: '', et_name: '', et_code: '' };

export const editStateObj = { condition: {}, matrix: {}, message: '' };

export const priorityUpdateObj = { cn_code: "", cn_name: "", cn_version: "", mx_code: "", mx_name: "", mx_version: "", wf_message: "" };
