import { useEffect, useState } from "react";
import { CreatePopup, Selectmenu, Textarea, Textbox } from "components/generic";
import './styles.scss';

export default function CreateFact(props) {

    const { createFactObj, dataType, addFactInput, handleCreatePopup, factExists } = props;

    const [disabled, setDisabled] = useState(false);

    useEffect(() => {
        const dis = !(createFactObj?.fa_key && createFactObj?.fa_name && createFactObj?.fa_allowedDataType && createFactObj?.fa_example);
        setDisabled(dis);
    }, [createFactObj])

    // get selectKey
    const getSelected = (list, id) => {
        return list?.find(ele => ele.value === id);
    }

    return (
        <>
            <CreatePopup disableSave={disabled || factExists} handleCreatePopup={handleCreatePopup}>
                <div className="fact-detail">
                    <Textbox label='Fact ID' mandatory placeholder="Eg: customername" name="fa_key" value={createFactObj?.fa_key} onChange={addFactInput} />
                    <Textbox label='Name' mandatory placeholder="Eg: Fullname" name="fa_name" value={createFactObj?.fa_name} onChange={addFactInput} error={factExists} errorMessage="* fact already exist" />
                    <Selectmenu label='Data Type' options={dataType} mandatory name="fa_allowedDataType" value={getSelected(dataType, createFactObj?.fa_allowedDataType)}
                        onChange={(e) => addFactInput({ e, type: 'fa_allowedDataType' })} />
                    <Textbox label='Example' mandatory placeholder="Eg. " name="fa_example" value={createFactObj?.fa_example} onChange={addFactInput} />
                </div>
                <div className="generic-detail">
                    <input type="checkbox" onChange={(e) => addFactInput({ obj: e, type: 'et_code' })} /> <span>Generic Fact</span>
                </div>
                <Textarea label='Description' placeholder="Type here" name="fa_description" value={createFactObj?.fa_description} onChange={addFactInput} />
            </CreatePopup>
        </>
    )
}