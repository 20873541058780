import PropTypes, { array } from 'prop-types';
import classNames from 'classnames';
import { map } from 'lodash';

import './style.scss';

export default function TabList(props) {

    const { tabList, tabActive, handleTabClick } = props;


    const list = map(tabList, (el, i) => {
        return <li className={classNames({ 'is-active': (tabActive === el.key) })} key={i} onClick={() => handleTabClick(el)}>{el.value}</li>
    });

    return (
        <>
            <ul className="tab-row">{list}</ul>
        </>
    )
}

TabList.propTypes = {
    tabList: array,
    tabActive: PropTypes.string,
}

TabList.defaultProps = {
    tabList: [],
    tabActive: ''
}


