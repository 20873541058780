import { CreatePopup, Textbox, Textarea } from 'components/generic';

export const AddEvents = (props) => {

    const { disableSave, handleCreatePopup, eventObj, addEventInput } = props;

    return (
        <>
            <CreatePopup disableSave={disableSave} handleCreatePopup={handleCreatePopup}>
                <h3>Add Event</h3>
                <Textbox label='Event ID' mandatory value={eventObj.et_code} placeholder="Eg: arrangement" name="et_code" onChange={addEventInput} />
                <Textbox label='Event Name' mandatory value={eventObj.et_name} placeholder="Eg: Arrangement" name="et_name" onChange={addEventInput} />
                <Textarea label='Event Description' value={eventObj.et_description} name="et_description" placeholder="Eg: Event for arrangement" onChange={addEventInput} />
            </CreatePopup>
        </>
    )
}