import PropTypes from 'prop-types';
import classNames from 'classnames';

import './style.scss';

export default function Popup(props) {

    const { xsmall, small, medium, compact, large, alignleft, children } = props;

    return (
        <>
            <div className="popup">
                <div className={classNames({ 'popup__inner': true, 'popup--xsmall': xsmall, 'popup--small': small, 'popup--medium': medium, 'popup--compact': compact, 'popup--large': large })}>
                    <div className={classNames({ 'popup__content': true, 'popup--left': alignleft })}>
                        {children}
                    </div>
                </div>
            </div>
        </>
    )
}

Popup.propTypes = {
    xsmall: PropTypes.bool,
    small: PropTypes.bool,
    medium: PropTypes.bool,
    compact: PropTypes.bool,
    large: PropTypes.bool,
    header: PropTypes.bool,
    alignleft: PropTypes.bool,
    footer: PropTypes.bool
}

Popup.defaultProps = {
    xsmall: false,
    small: false,
    medium: false,
    compact: false,
    large: false,
    close: false,
    header: false,
    alignleft: false,
    footer: false
}


