import Routers from './routers';
import { ErrorBoundary } from 'react-error-boundary';
import { PageNotFound } from 'components/generic';

function App() {
  return (
    <>
      <ErrorBoundary FallbackComponent={PageNotFound}>
          <Routers />
      </ErrorBoundary>
    </>
  )
}

export default App

