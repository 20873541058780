import { Images } from 'assets/Images';
import { Button } from 'components/generic';

import './style.scss';

export default function CreateNew(props) {
    const { label, content, title, handleCreate } = props;
    return (
        <>
            <div className="creat-new">
                <div className="creat-new__row">
                    <img src={Images.createNew} alt="create New" />
                    <h2>{title}</h2>
                    <p>{content}</p>
                    <Button label={label} primary transparent compact handleOnSubmit={handleCreate} />
                </div>
            </div>
        </>
    )
}
