import './style.scss';

export default function PriorityCard(props) {
    const { children } = props;
    return (
        <>
            <div className="priority-card">
                {children}
            </div>
        </>
    )
}
