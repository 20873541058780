import PropTypes from 'prop-types';
import { ButtonRow } from '..';

import './style.scss';

export default function BottomSection(props) {

    const { disableSave, handleButtonClick } = props;

    return (
        <>
            <div className="button-section">
                <ButtonRow label="Goback" label1="Save" disableSave={disableSave} handleButtonClick={handleButtonClick} />
            </div>

        </>
    )
}

BottomSection.propTypes = {
    handleButtonClick: PropTypes.func
}

BottomSection.defaultProps = {
    handleButtonClick: () => { }
}

