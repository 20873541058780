import { useState, useEffect } from 'react';
import TextInput from 'react-autocomplete-input';
import 'react-autocomplete-input/dist/bundle.css';
import { Button, ListCard, Popup, Textbox } from 'components/generic';
import './styles.scss';

export default function CreateExpression(props) {

    const { fact, operators, factlist, onCreateFact } = props;
    const [expand, setExpand] = useState(false);
    const [filterList, setFilterList] = useState();
    const [selected, setSelected] = useState();
    const [factName, setFactName] = useState('');
    const [factDesc, setFactDesc] = useState('');
    const [fields, setFields] = useState([]);
    const filterFact = factlist?.map(ele => ele?.fa_name);

    useEffect(() => {
        setFilterList(operators);
        setSelected({ name: '', content: '', example: '' });
    }, [operators]);

    useEffect(() => {
        fact?.action !== 'copy' && setFactName(fact?.name);
        setFactDesc(fact?.formula);
    }, [fact]);

    // search operator
    const onSearch = (e) => {
        const filter = operators?.filter(op => op.id.toLowerCase().includes(e.toLowerCase()));
        setFilterList(filter);
    }

    // add operator click handler
    const handleAddOperator = (e) => {
        setFactDesc(`${factDesc}${e?.id}()`);
    }

    // handle factName
    const handleInput = (e) => {
        setFactName(e.target.value);
    }

    // handle submit create fact
    const handleSubmit = (e) => {
        onCreateFact({ status: e, key: factName?.toUpperCase() , action: fact?.action, name: factName, formula: factDesc, fields: fields });
    }

    // handle select operator
    const changeOnSelect = (trigger, slug) => {
        let key = '';
        factlist?.find(ele => ele?.fa_name === slug && (key = ele?.fa_key));
        setFields([key, ...fields]);
        return slug;
    }

    // handle on select
    const handleSelectList = (e) => {
        setSelected({ name: e.title, content: e.content, example: e.example });
    }

    return (
        <Popup medium alignleft>
            <Textbox label="Name" requied disabled={fact?.action === 'view'} placeholder="Eg: Fact Name" value={factName} name="name" onChange={handleInput} />
            <TextInput className="formula" placeholder="Eg: Fact Name" value={factDesc} disabled={fact?.action === 'view'} options={filterFact} onChange={(e) => setFactDesc(e)} changeOnSelect={changeOnSelect} />

            {fact?.action !== 'view' && <div className={expand ? "expand" : "shrink"} onClick={() => setExpand(!expand)}>Use Operators</div>}

            {expand && <div  className="create-fact__list">
                <div className="create-fact__col">
                    <ListCard search={true} checkBox={false} list={filterList} handleSelect={handleAddOperator} handleSearch={onSearch} handleSelectList={handleSelectList}/></div>
                <div className="create-fact__col">
                    <h3>{selected.name}</h3><p>{selected.content}</p><p>{selected.example}</p>
                </div>
            </div>}
            
            <div className="create-fact__button">
                <Button label="Go Back" compact transparent primary handleOnSubmit={() => handleSubmit('back')} />
                <Button label="Submit" disabled={factName?.length === 0 || fact?.action === 'view'} compact primary handleOnSubmit={() => handleSubmit('submit')} />
            </div>
        </Popup>
    )
}
