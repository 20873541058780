import { DecisionBlock } from 'components/generic';
import { apiOp, tabs } from "config";
import './style.scss';

export default function Conditional(props) {

    const { createObj, factList, expressionList, saveRule, dockerState, createFactObj, addFactInput, dataType, utilityOperator, handleModalSubmit, handleCreateFact, handleCreateExpression, handleSaveRule, factExists } = props;

    const title = 'Creating Exception for';
    const tablist = tabs;
    const operatorListEvent = apiOp;
    const listHeight = { 'height': 'calc(100vh - 315px)' };
    const rulesHeight = { 'height': 'calc(100vh - 220px)' };

    return (
        <>
            <DecisionBlock title={title} saveRule={saveRule} state={dockerState} viewOnly={dockerState === 'view'} name={createObj?.cn_name} description={createObj?.cn_description} severity={createObj?.cn_severity} rule={createObj?.cn_rule} listHeight={listHeight} rulesHeight={rulesHeight} factListEvent={factList} expressionList={expressionList} operatorListEvent={operatorListEvent} tablist={tablist} operators={utilityOperator} createFactObj={createFactObj} addFactInput={addFactInput} dataType={dataType} onModalSubmit={handleModalSubmit} onCreateFact={handleCreateFact} onCreatExpression={handleCreateExpression} onSaveRule={handleSaveRule} factExists={factExists} />
        </>
    )
}