import React, { useState } from 'react';
import PropTypes, { array } from 'prop-types';
import classNames from 'classnames';
import { map } from 'lodash';
import { Button, FilterSearch } from '..';
import { Images } from 'assets/Images';

import './style.scss';

export default function TitleSection(props) {
    const { handleTabClick, title, count, tab, search, activeTab, headerList, id, handleAdd, handleSearch, searchList, cardList, searchValue, searchInput, add, appList, showDrop, handleTitleDrop, handleTitleSelectedDrop, application } = props;

    const droplist = map(appList, (el, i) => {
        return <li key={i} onClick={() => handleTitleSelectedDrop(el.value, 'sa_id')} >{el.label}</li>
    })


    const list = map(headerList, (el, i) => {
        return (
            <>
                {el.type === 'tab' && <li className={classNames({ 'is-active': (activeTab === el.key) })} key={i} onClick={() => handleTabClick(el)}>{el.value}</li>}
                {el.type === 'dropdown' && application && <li key={i} className={classNames({ 'is-active': (activeTab === el.key) })} onClick={() => handleTitleDrop(!showDrop)}>
                    {el.value}
                    <img src={Images.dropdown} />
                    {showDrop && <ul className="title-wrap__drop scroll-bar">{droplist}</ul>}
                </li>}
            </>
        )
    })

    return (
        <>
            <div id={id} className="title-wrap">
                <div className="title-wrap__title">
                    <h2>{title} <span>{count} </span></h2>
                </div>
                <div className={classNames({ 'title-wrap__row': true, 'title-wrap--end': !tab, 'title-wrap--start': !search })}>
                    {tab && <ul className="title-wrap__tab" >{list}</ul>}
                    <div className="title-wrap__search">
                        {search && <FilterSearch placeholder="Search" last searchList={searchList} cardList={cardList} searchFunction={handleSearch} value={searchValue} input={searchInput} />}
                        {add && <Button primary xsmall label="Add New" handleOnSubmit={handleAdd} />}
                    </div>

                </div>
            </div>
        </>
    );
}

TitleSection.propTypes = {
    title: PropTypes.string,
    count: PropTypes.number,
    tab: PropTypes.bool,
    search: PropTypes.bool,
    headerList: array,
    activeTab: PropTypes.string,
    id: PropTypes.string,
    SearchValue: PropTypes.string,
}

TitleSection.defaultProps = {
    title: '',
    count: 0,
    tab: false,
    search: false,
    activeTab: '',
    headerList: [],
    id: '',
    SearchValue: ''
}