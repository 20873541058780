import { useState, useContext, useEffect } from "react";
import { useQuery } from "react-query";
import { find, map } from "lodash";
import * as api from 'actions';
import { BottomSection, Conditional, ConditionalDetails, PopupConfimation, MatrixDetails, CreatePopup, Matrix, TabList, BreadCrumb, ListCard, ButtonRow, Popup, Toaster } from "components/generic";
import { matrixTab } from "config";
import { conditionCreateObj, factCreateObj, matrixCreateObj } from "interface";
import { ReferenceContext, MatrixContext } from "providers";

import './styles.scss';
import { Images } from "assets/Images";
import { popup } from "config";


export const ManualFlow = (props) => {

    const { showManual, manualConditionObj, manualMatrixObj, manualState, application, event, appList, eventList, handleManualPopup, handleReset } = props;

    const referenceData = useContext(ReferenceContext);
    const [createObj, setCreateObj] = useState(conditionCreateObj);
    const [saveRule, setSaveRule] = useState(false);
    const [createFactObj, setCreateFactObj] = useState(factCreateObj);
    const [factList, setFactList] = useState([]);
    const [expressionList, setExpressionList] = useState([]);
    const [bottonState, setBottonState] = useState('');
    const [showPopup, setShowPopup] = useState(false);
    const [roles, setRoles] = useState([]);
    const [activeState, setActiveState] = useState('general');
    const [breadCrumb, setbreadCrumb] = useState(['Workflow', 'Matrix']);
    const [disbleCondition, setDisableCondition] = useState(true);
    const [conditionExists, setConditionExist] = useState(false);

    const [matrixObj, setMatrixObj] = useState(matrixCreateObj);

    const [showCheckPopup, setShowCheckPopup] = useState(false);
    const [attachedWorkflows, setAttachedWorkflows] = useState([]);

    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    let createFact = true;
    let selectedWorkflow = [];
    const factObj = { recordCount: -1, start_index: 0, sa_id: '' };
    const createExpressionObj = { ...factCreateObj, fa_formulaType: 'FORMULA' };
    const utilityOperator = find(referenceData?.referenceData, el => el.ard_factsOperatorList)?.ard_factsOperatorList;
    const dataType = find(referenceData?.referenceData, el => el.ard_dataType)?.ard_dataType?.map(ele => ({ label: ele.name, value: ele.value }));
    const severityList = find(referenceData?.referenceData, el => el.ard_severity)?.ard_severity?.map(ele => ({ label: ele.name, value: ele.value }));

    useEffect(() => {
        const ev = eventList?.filter(ele => ele?.value === manualConditionObj?.et_code);
        setCreateFactObj(state => ({ ...state, et_code: manualConditionObj?.et_code, et_name: ev[0]?.label }));
        factObj.sa_id = manualConditionObj.sa_id;
        refetchFactList();
        if (manualState !== 'add') {
            setCreateObj(state => ({ ...state, ...manualConditionObj }));
            manualState === 'copy' && setCreateObj(state => ({ ...manualConditionObj, cn_name: '', cn_description: '', cn_severity: '' }));
            setMatrixObj(state => ({ ...manualMatrixObj, sa_id: application }));
            manualState === 'copy' && setMatrixObj(state => ({ ...manualMatrixObj, mx_name: '' }));
        }
        showManual === 'matrix-popup' && roleRefetch();
    }, [manualState, manualConditionObj, manualMatrixObj])

    useEffect(() => {
        application && setCreateObj(state => ({ ...state, sa_id: application }));
        application && setMatrixObj(state => ({ ...state, sa_id: application }));
        application && (factObj.sa_id = application.value) && refetchFactList();
        event && setCreateObj(state => ({ ...state, et_code: event }));
    }, [application, event])

    // get roles
    const { refetch: roleRefetch } = useQuery('getRoles', () => api.get(`roles/list?sa_id=${application}`), {
        enabled: false, onSuccess: (res) => {
            const a = map(res?.data?.data?.result, el => ({ value: el.name, label: el.name }));
            setRoles(a);
        }
    });

    // fact listing api
    const { refetch: refetchFactList } = useQuery(['getFactList', factObj], () => api.getListing('/fact/list', factObj), {
        onSuccess: (data) => {
            const [list1, list2] = [data?.data?.data?.filter(ele => ele?.fa_formulaType === 'DIRECT'), data?.data?.data?.filter(ele => ele?.fa_formulaType === 'FORMULA')]
            setFactList(list1); setExpressionList(list2);
        }, refetchOnWindowFocus: false, enabled: false
    });

    // create condition api
    const { refetch: refetchCreateCondition } = useQuery(['createConditional', createObj], () => api.create('/condition/add', createObj), {
        onSuccess: (data) => {
            data?.data?.code === 200 ? setError(false) : setError(true);
            setErrorMessage(data?.data?.message);
            data?.data?.code === 200 && reset();
        }, refetchOnWindowFocus: false, enabled: false
    });

    // edit condition api
    const { refetch: refetchEditCondition } = useQuery(['createConditional', createObj], () => api.update('/condition/edit', createObj), {
        onSuccess: (data) => {
            data?.data?.code === 200 ? setError(false) : setError(true);
            setErrorMessage(data?.data?.message);
            data?.data?.code === 200 && reset();
        }, refetchOnWindowFocus: false, enabled: false
    });

    // create fact
    const { refetch: refetchCreateFact } = useQuery(['createFact'], () => api.create('/fact/add', createFact ? createFactObj : createExpressionObj), {
        onSuccess: (data) => {
            data?.data?.code === 200 ? setError(false) : setError(true);
            setErrorMessage(data?.data?.message);
            factObj.sa_id = createObj.sa_id;
            refetchFactList();
            setCreateFactObj(state => ({ ...factCreateObj, sa_id: createObj?.sa_id }));
        }, refetchOnWindowFocus: false, enabled: false
    });

    // add Matrix
    const { refetch: addRefetch } = useQuery(['addMatrix', matrixObj], () => api.create('matrix/add', matrixObj), {
        enabled: false, onSuccess: (data) => {
            // setCreateState(false);
            data?.data?.code === 200 ? setError(false) : setError(true);
            setErrorMessage(data?.data?.message);
            data?.data?.code === 200 && reset();
        }
    });

    // edit matrix
    const { refetch: editMatrix } = useQuery(['editMatrix', matrixObj], () => api.update(`matrix/${matrixObj.mx_code}/${matrixObj.mx_version}`, matrixObj), {
        enabled: false,
        onSuccess: (data) => {
            // setCreateState(false);
            data?.data?.code === 200 ? setError(false) : setError(true);
            setErrorMessage(data?.data?.message);
            data?.data?.code === 200 && reset();
        }
    });

    // check event does exist
    const { refetch: doesConditionExist } = useQuery(['doesExist'], () => api.get(`/Condition/${encodeURIComponent(createObj?.cn_name)}`), {
        onSuccess: (conditionEx) => { conditionEx?.data?.data?.length === 0 ? setConditionExist(false) : setConditionExist(true) }, refetchOnWindowFocus: false, enabled: false
    });

    // get attached Workflow for matrix
    const { refetch: attachedMatrixWF } = useQuery(['', matrixObj], () => api.get(`matrix/${matrixObj?.mx_code}/${matrixObj?.mx_version}`), {
        enabled: false, onSuccess: (res) => {
            const list = res?.data?.data[0]?.attachedWorkflow?.map(ele => ({ id: ele?.wf_name, wf_name: ele?.wf_name, wf_code: ele?.wf_code, wf_version: ele?.wf_version }));
            if (list?.length > 0) {
                setAttachedWorkflows(list);
                setShowCheckPopup(true);
            } else {
                editMatrix();
            }
        }
    });

    // get attached workflow for condition
    const { refetch: attachedConditionWF } = useQuery(['attachedWF'], () => api.get(`/condition/link/workflow/${createObj?.cn_code}/${createObj?.cn_version}`), {
        onSuccess: (res) => {
            const list = res?.data?.data[0]?.attachedWorkflow?.map(ele => ({ id: ele?.wf_name, ...ele }));
            if (list?.length > 0) {
                setAttachedWorkflows(list);
                setShowCheckPopup(true);
            } else {
                refetchEditCondition();
            }
        }, refetchOnWindowFocus: false, enabled: false
    });

    useEffect(() => {
        errorMessage && setTimeout(() => setErrorMessage(''), 2000)
    }, [errorMessage]);

    useEffect(() => {
        const disabled = !(!conditionExists && createObj?.sa_id?.length > 0 && createObj?.cn_name?.length > 0 && createObj?.et_code?.length > 0 && createObj?.cn_severity?.length > 0);
        setDisableCondition(disabled);
    }, [createObj, conditionExists]);

    useEffect(() => {
        createObj?.cn_name && doesConditionExist();
    }, [createObj?.cn_name, doesConditionExist]);

    const addConditionInput = (e, key) => {
        key === 'cn_severity' ? setCreateObj(state => ({ ...state, [key]: e.value }), []) : setCreateObj(state => ({ ...state, [key]: e.target.value }), []);
    }

    const handleCreatePopup = (e) => {
        e === 'Cancel' && setCreateObj(state => ({ ...conditionCreateObj, sa_id: application.value, et_code: event.value }));
        handleManualPopup(e);
        setDisableCondition(true);
        setConditionExist(false);
    }

    const handleCreateMatrix = (e) => {
        setbreadCrumb([...breadCrumb, 'General Details']);
        e === 'Cancel' && setMatrixObj(state => ({ ...matrixObj, sa_id: application.value }));
        handleManualPopup(e);
    }

    const handleBottomSection = (e) => {
        setSaveRule(true);
        setBottonState(e);
        setShowPopup(true);
    }

    const handleConfimation = (e) => {
        setShowPopup(false);
        (e === 'No') && setSaveRule(false);
        if (showManual === 'condition') {
            (e === 'Yes' && bottonState === 'Goback') && reset();
            (e === 'Yes' && bottonState === 'Goback') && setCreateObj(conditionCreateObj);
            (e === 'Yes' && bottonState === 'Save') && createConditional();
        } else if (showManual === 'matrix') {
            (e === 'Yes' && bottonState === 'Goback') && reset();
            (e === 'Yes' && bottonState === 'Goback') && setMatrixObj(matrixCreateObj);
            (e === 'Yes' && bottonState === 'Save') && createMatrix();
        }

    }

    const handleModalSubmit = (e) => {
        setCreateObj(state => ({ ...state, cn_name: e.name }), []);
        setCreateObj(state => ({ ...state, cn_description: e.description }), []);
        setCreateObj(state => ({ ...state, cn_severity: e.severity }), []);
    }

    const handleSaveRule = (e) => {
        setCreateObj(state => ({ ...state, cn_rule: e.rule }), []);
    }

    // handle create condition
    const createConditional = () => {
        if (manualState === 'add' || manualState === 'copy') {
            refetchCreateCondition();
        } else if (manualState === 'edit') {
            createObj?.cn_mode === "PUBLISHED" ? attachedConditionWF() : refetchEditCondition();
        }
    }

    const createMatrix = (e) => {
        if (manualState === 'add' || manualState === 'copy') {
            addRefetch();
        } else if (manualState === 'edit') {
            matrixObj?.mx_mode === 'PUBLISHED' ? attachedMatrixWF() : editMatrix();
        }
    }

    const handleAddFact = (e) => {
        if (e?.type === 'fa_allowedDataType') {
            setCreateFactObj(state => ({ ...state, fa_allowedDataType: e?.e?.value }), []);
        } else if (e?.type === 'et_code') {
            const ev = eventList?.filter(ele => ele?.value === manualConditionObj?.et_code);
            e.obj?.target.checked ? setCreateFactObj(state => ({ ...state, et_code: 'ALL', et_name: 'ALL' }), []) : setCreateFactObj(state => ({ ...state, et_code: createObj?.et_code, et_name: ev[0]?.label }), []);
        } else {
            setCreateFactObj(state => ({ ...state, [e.target.name]: e.target.value }), []);
        }
        setCreateFactObj(state => ({ ...state, fa_formulaType: 'DIRECT' }), []);
    }

    const handleCreateFact = (e) => {
        createFact = true;
        const ev = eventList?.filter(ele => ele?.value === createObj?.et_code);
        e === 'Cancel' && setCreateFactObj(state => ({ ...factCreateObj, sa_id: createObj?.sa_id, et_code: createObj?.et_code, et_name: ev[0]?.label }));
        e === 'Create' && refetchCreateFact();
    }

    const handleCreateExpression = (e) => {
        createFact = false;
        createExpressionObj.sa_id = createObj?.sa_id;
        createExpressionObj.fa_key = e?.key;
        createExpressionObj.fa_name = e?.name;
        createExpressionObj.fa_formula = e?.formula;
        createExpressionObj.fa_fields = e?.fields;
        createExpressionObj.fa_formulaType = 'FORMULA';
        refetchCreateFact();
    }


    const handleAddTab = (e) => {
        const bread = [...breadCrumb];
        const state = ['add', 'copy'].includes(manualState) ? 'Add' : manualState === 'edit' ? 'Edit' : 'View'
        bread[2] = e.key === 'general' ? e.value : `${state} ${e.value}`;
        setbreadCrumb(bread);
        setActiveState(e.key);
        e.key === 'matrix' && roleRefetch();
    }

    const reset = () => {
        handleReset();
        setDisableCondition(true);
        setConditionExist(false);
        setShowCheckPopup(false);
        selectedWorkflow = [];
    }

    const handleInput = ({ target: { name, value } }) => {
        setMatrixObj(state => ({ ...state, [name]: value }), []);
    }

    const handleDrop = (name, value) => {
        setMatrixObj(state => ({ ...state, [name]: value.value }), []);
    }

    // handle toaster
    const handleToaster = (e) => {
        e === 'close' && setErrorMessage('');
    }

    const handleStatus = (e, type) => {
        showManual === 'condition' ? setCreateObj(state => ({ ...state, cn_mode: type }), []) : setMatrixObj(state => ({ ...state, mx_mode: type }), []);
    }

    const handleCheckPopupSubmit = (e) => {
        (e === 'Goback') && setAttachedWorkflows([]);
        if (e === 'Save') {
            showManual === 'condition' ? setCreateObj(state => ({ ...state, wf_details: selectedWorkflow }), []) : setMatrixObj(state => ({ ...state, wf_details: selectedWorkflow }), [])
            setTimeout(() => showManual === 'condition' ? refetchEditCondition() : editMatrix(), 200);
        }
        setShowCheckPopup(false);
    }

    const handlePopupChecked = (e) => {
        selectedWorkflow = e;
    }

    const handleSelectList = (e) => { }

    return (
        <>
            {(manualState === 'add' || manualState === 'copy') && showManual === 'condition-popup' && <ConditionalDetails disbleCondition={disbleCondition} conditionExists={conditionExists} createObj={createObj} appList={appList} eventList={eventList} manualFlow={true} severityList={severityList} addConditionSelect={addConditionInput} addConditionInput={addConditionInput} handleCreatePopup={handleCreatePopup} />}

            {showManual === 'condition' && <>
                <div className="condition-layout">
                    <Conditional createObj={createObj} saveRule={saveRule} dockerState={manualState} factList={factList} expressionList={expressionList} createFactObj={createFactObj} dataType={dataType} utilityOperator={utilityOperator} addFactInput={handleAddFact} handleModalSubmit={handleModalSubmit} handleCreateFact={handleCreateFact} handleCreateExpression={handleCreateExpression} handleSaveRule={handleSaveRule} />
                </div><BottomSection disableSave={manualState === 'view'} handleButtonClick={handleBottomSection} /></>
            }

            {manualState === 'add' && showManual === 'matrix-popup' &&
                <MatrixContext.Provider value={{ createObj: [matrixObj, setMatrixObj], appList, dockerState: manualState }}>
                    <CreatePopup handleCreatePopup={handleCreateMatrix} manual={true}  ><MatrixDetails disableApp={true} manual={true} handleInput={handleInput} handleDrop={handleDrop} /></CreatePopup>
                </MatrixContext.Provider>
            }

            {showManual === 'matrix' &&
                <MatrixContext.Provider value={{ createObj: [matrixObj, setMatrixObj], appList, dockerState: manualState }}>
                    <BreadCrumb path={breadCrumb} />
                    <TabList tabList={matrixTab} tabActive={activeState} handleTabClick={handleAddTab} />
                    {activeState === 'general' && <MatrixDetails manual={false} disableApp={true} handleInput={handleInput} handleDrop={handleDrop} />}
                    {activeState === 'matrix' && <Matrix roles={roles} />}
                    <BottomSection disableSave={manualState === 'view'} handleButtonClick={handleBottomSection} />
                </MatrixContext.Provider>}

            {showPopup && <PopupConfimation showstatus={bottonState === 'Save'} activeStatus={showManual === 'matrix' ? matrixObj?.mx_mode : createObj?.cn_mode} handleChecked={handleStatus} heading="Warning" content={bottonState === 'Goback' ? popup.back : manualState === 'add' ? popup.save : manualState === 'edit' ? popup.update : ''} handleConfimation={handleConfimation} />}

            {errorMessage && <Toaster success={!error} error={error} header={error ? 'Oops!!! something went wrong' : 'Yay! Everything Worked!'} content={errorMessage} handleUndoClose={handleToaster} />}

            {showCheckPopup && <Popup small alignleft>
                <div className='popup-wrapper'>
                    <img src={Images.warning} alt="warning" />
                    <h5>The <span>"{showManual === 'condition' ? createObj.cn_name : matrixObj.mx_name}"</span> has been linked to the workflows below. To overwrite the version, please select the workflow</h5>
                </div>
                <ListCard list={attachedWorkflows} checkBox={true} handleChecked={handlePopupChecked} handleSelectList={handleSelectList} />
                <div className='buttons'><ButtonRow label="Goback" label1="Save" handleButtonClick={handleCheckPopupSubmit} /></div>
            </Popup>}
        </>
    )
}