import { Header, SideBar } from 'components/hoc';

import './style.scss';

export const LayoutComponent = (props) => {

    return (
        <>
            <section className="layout-wrap">
                <Header />
                <SideBar />
                <div className="layout-wrap__container">
                    {props.children}
                </div>
            </section>
        </>
    )
}

