import PropTypes from 'prop-types';
import { Images } from 'assets/Images';

import './styles.scss';
import { colors } from 'config';

export default function ListCard(props) {
  const { search, checkBox, list, handleSelect, handleSearch, handleSelectList, handleChecked } = props;

  let checkedList = [];

  const handleCheckBox = (e, item) => {
    if (e.target.checked) {
        checkedList.push(item);
    } else {
        checkedList = checkedList?.filter(ele => ele.id !== item.id);
    }
    handleChecked(checkedList);
  }

  return (
    <>
        <div className="card-list">
            {search && <div className="card-list__search">
                <div className="card-list__search--wrap">
                    <img src={Images.search} alt="search"/>
                    <input type="text" placeholder="Search" onKeyUp={(e) => handleSearch(e.target.value)} />
                </div>
            </div>}
            <ul>{list?.map((item, index) => (
                <li key={index} onClick={(e) => handleSelectList(item)}>
                    <div>{checkBox && <span style={{ 'backgroundColor': colors[index % colors?.length] }}>{item?.id?.substring(0,2)}</span>} {item?.id} {item?.wf_version}</div>
                    {!checkBox ? <img src={Images.add} alt="add" onClick={(e) => handleSelect(item)} /> :
                    <input type="checkbox" onChange={(e) => handleCheckBox(e, item)}/>}
                </li>
            ))}</ul>
        </div >
    </>
  );
}

ListCard.propTypes = {
    search: PropTypes.bool,
    checkBox: PropTypes.string,
    list: PropTypes.array,
    handleSelect: PropTypes.func,
}

ListCard.defaultProps = {
    search: false,
    checkBox: false,
    list: [],
    handleSelect: () => { },
}
