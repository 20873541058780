import PropTypes from 'prop-types';
import { ButtonRow } from '..';

import './style.scss';

export default function CreatePopup(props) {

    const { children, disableSave, handleCreatePopup } = props;

    return (
        <>
            <div className="create-popup">
                <div className='create-popup__inner'>
                    <div className='create-popup__content'>
                        {children}
                    </div>
                    {/* <div className="create-popup__button" >
                        <Button label="Cancel" primary transparent />
                        <Button label="Create" primary />
                    </div> */}
                    <div className="create-popup__button" >
                        <ButtonRow label="Cancel" label1="Create" disableSave={disableSave} handleButtonClick={handleCreatePopup} />
                    </div>
                </div>
            </div>
        </>
    )
}

CreatePopup.propTypes = {
    children: PropTypes.node.isRequired,
}