import DatePicker from 'react-date-picker';
import PropTypes from 'prop-types';
import { Images } from 'assets/Images';

import './style.scss';

export default function DatePickers(props) {
    const { onChange, value, last, label, minDate, isDisabled, mandatory } = props;

    const validDateInput = function (e) {
        e.target.value = '';
    };

    return (
        <>
            <div className={`datepicker-row ${last ? 'datepicker-row--last' : isDisabled ? 'datepicker-row--disabled' : ''}`}>
                {label && <label>{label} {mandatory && <sup>*</sup>}</label>}
                <div className='datepicker-row__block'>
                    <DatePicker onChange={onChange} value={value} minDate={minDate} format="dd-MM-y" disabled={isDisabled} dayPlaceholder='DD' monthPlaceholder='MM' yearPlaceholder='YYYY' onInput={(e) => validDateInput(e)} />
                    <img src={Images.calendar} alt='calender' />
                </div>
            </div>
        </>
    )
}

DatePickers.propTypes = {
    value: PropTypes.any,
    last: PropTypes.bool,
    label: PropTypes.string,
    onChange: PropTypes.func
}

DatePickers.defaultProps = {
    value: '',
    last: false,
    label: '',
    onChange: () => { }
}
