import { useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import { map, find, isEmpty, uniqBy, omit, cloneDeep, orderBy } from 'lodash';
import * as moment from 'moment';
import { useInView } from 'react-intersection-observer';
import * as api from 'actions';
import { WorkFlowContext } from 'providers';
import { conditionCreateObj, workflowCreateObj, matrixCreateObj, eventCreateObj } from 'interface';
import { headerTab, workflowTab, dockerList, listRequestObj, popup, workflowSearchList } from 'config';
import { CardComponent, BreadCrumb, TabList, TitleSection, BottomSection, CardRow, PopupConfimation, Docker, Loader, Toaster, NoRecord } from 'components/generic';
import { WorkFlow } from './components/workFlow';
import { GeneralDetails } from './components/general-details';
import { AddEvents } from './components/add-event';
import { ManualFlow } from './components/manual-flow';

export default function WorkFlowMaintenance() {

    const [resObj, setResponse] = useState(listRequestObj);
    const [workflowList, setWorkflowList] = useState([]);
    const [activeTab, setTab] = useState(headerTab[0].key);
    const [activeState, setActiveState] = useState('dashboard');
    const [breadCrumb, setbreadCrumb] = useState(['Workflows']);
    const [createObj, setCreateObj] = useState(workflowCreateObj);
    const [appList, setAppList] = useState([]);
    const [eventList, setEventList] = useState([{ value: 'add-event', label: 'Add new event' }]);
    const [showPopup, setShowPopup] = useState(false);
    const [showAddEvent, setAddEvent] = useState(false);
    const [eventObj, setEventObj] = useState(eventCreateObj);
    const [showDocker, setDocker] = useState(false);
    const [selectlist, setSelectList] = useState([]);
    const [dockerState, setDockerState] = useState('');
    const [bottonState, setBottonState] = useState('');
    const [creatState, setCreateState] = useState(true);
    const [searchInput, setSearchInput] = useState('');
    const [searchValue, setSearchValue] = useState('');
    const [disableSave, setDisabledSave] = useState(true);
    const [disbleEvent, setDisableEvent] = useState(true);
    const [eventExists, setEventExists] = useState(false);
    const [activeTabState, setActiveTabState] = useState('condition');

    const [ref, inView] = useInView();
    const [startIndex, setStartIndex] = useState(0);
    const [totalList, setTotalList] = useState(0);

    const [showManual, setShowManual] = useState('');
    const [manualState, setManualState] = useState('');
    const [manualConditionObj, setManualConditionObj] = useState(conditionCreateObj);
    const [manualMatrixObj, setManualMatrixObj] = useState(matrixCreateObj);

    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [permission, setPermission] = useState([]);
    const [dockerData, setDockerData] = useState(dockerList);

    const [showDrop, setShowDrop] = useState(false);
    const [norecord, setNorecord] = useState(false);

    let addObj = [];
    let statusObj = {};
    let selectedCard = [];
    const cObj = { cn_code: '', cn_version: '' };
    const mObj = { mx_code: '', mx_version: '' };
    const innerResObj = { startIndex: 0, recordCount: -1, searchValue: '', sort: [{ id: "topUsed", value: -1 }], filter: [] };

    const loadPermission = () => {
        const allPermissions = api.decrypting(localStorage.getItem('_ro'));
        const parsedPermission = allPermissions ? JSON?.parse(allPermissions) : {};
        const dockerPermission = parsedPermission?.workflow_maintenance?.map(el => el?.replace(/_.*/, '')?.toLowerCase());
        const docker = dockerList.filter(el => dockerPermission?.includes(el.key));
        setDockerData(docker);
        setPermission(parsedPermission?.workflow_maintenance);
    };

    const { refetch: refetchWFList, isLoading } = useQuery(['getWorkFlow', resObj], () => api.getListing('/workflow/listing', resObj), {
        refetchOnWindowFocus: false, enabled: creatState, onSuccess: (data) => {
            resObj?.startIndex === 0 && setStartIndex(0);
            if (data?.data?.data?.length > 0) {
                resObj?.startIndex === 0 ? setWorkflowList(data?.data?.data[0]?.result) : setWorkflowList(state => ([...state, ...data?.data?.data[0]?.result]));
                setTotalList(data?.data?.data[0]?.total);
            } else {
                setWorkflowList([]);
                setTotalList(0);
            }
        }
    });

    const { data: conditionList, refetch: refetchConditionList } = useQuery(['getCondition', innerResObj], () => api.getListing('/condition/list', innerResObj), {
        refetchOnWindowFocus: false, enabled: false
    });

    const { data: matrixList, refetch: refetchMatrixList } = useQuery(['getApproval', innerResObj], () => api.getListing('/matrix/list', innerResObj), {
        refetchOnWindowFocus: false, enabled: false
    });

    // get application
    const { refetch: applicationRefetch } = useQuery('getApplication', () => api.get('application/list'), {
        enabled: true,
        onSuccess: (data) => {
            const a = data?.data?.data?.map(el => ({ value: el.sa_id, label: el.sa_name }));
            setAppList(a);
        }
    });

    // get event
    const { refetch: eventRefetch } = useQuery('getEventByApplication', () => api.get(`event/list?sa_id=${createObj.sa_id}`), {
        enabled: false,
        onSuccess: (data) => {
            const b = data?.data?.data?.map(el => ({ value: el.et_code, label: el.et_name })) || [];
            setEventList([...eventList, ...b]);
        }
    });

    // doesexist for workflow name
    const { data: doesExistWorkflow, refetch: doesExistWorkflowRefetch } = useQuery(['checkWorkflow', createObj.wf_name], () => api.get(`/workflow/exist/${createObj.wf_name}`), {
        enabled: false
    });

    // doesexist for event name
    const { refetch: doesExistEventRefetch } = useQuery(['checkEvent', eventObj.et_code], () => api.get(`/event?et_name=${eventObj?.et_name}&et_code=${eventObj?.et_code}&sa_id=${createObj.sa_id}`), {
        enabled: false, onSuccess: (eventEx) => { setEventExists(eventEx?.data?.data) }
    });

    // add event
    const { refetch: addEvent } = useQuery(['addEvent', addObj], () => api.post('/event/add', eventObj), {
        enabled: false,
        onSuccess: (data) => {
            data?.data?.code === 200 ? setError(false) : setError(true);
            setErrorMessage(data?.data?.message);
            setAddEvent(false);
            eventRefetch();
            setCreateObj(state => ({ ...state, et_code: eventObj.et_code }), []);
            setEventObj(eventCreateObj);
        }
    });

    // get workflow by code
    const { refetch: getWorkflowById } = useQuery(['getWorkflowById', selectedCard], () => api.get(`workflow/details?wf_code=${selectedCard[0].wf_code}&wf_version=${selectedCard[0].wf_version}`), {
        enabled: false,
        onSuccess: (res) => {
            const obj = {
                et_code: res?.data?.data[0]?.et_code, sa_id: res?.data?.data[0]?.sa_id, wf_description: res?.data?.data[0]?.wf_description,
                wf_details: res?.data?.data[0]?.wf_details, wf_endDate: res?.data?.data[0]?.wf_endDate, wf_mode: res?.data?.data[0]?.wf_mode, wf_name: res?.data?.data[0]?.wf_name,
                wf_startDate: res?.data?.data[0]?.wf_startDate
            };
            if (dockerState === 'edit') {
                obj.wf_code = res?.data?.data[0]?.wf_code;
                obj.wf_status = res?.data?.data[0]?.wf_status;
                obj.wf_version = res?.data?.data[0]?.wf_version;
                obj.et_name = res?.data?.data[0]?.et_name;
            }
            setCreateObj(obj);
            formCreateObject(obj);
            setManualConditionObj(state => ({ ...state, sa_id: obj?.sa_id, et_code: obj?.et_code }), []);
        }
    });

    // add workflow
    const { refetch: addWorkflow } = useQuery(['addWorkflow', addObj], () => api.create('/workflow/add', addObj), {
        enabled: false,
        onSuccess: (data) => {
            data?.data?.code === 200 ? setError(false) : setError(true);
            setErrorMessage(data?.data?.message);
            setCreateState(false);
            reset();
        }
    });

    // edit workflow
    const { refetch: editWorkflow } = useQuery(['editWorkflow', addObj], () => api.create('/workflow/edit', addObj), {
        enabled: false,
        onSuccess: (data) => {
            data?.data?.code === 200 ? setError(false) : setError(true);
            setErrorMessage(data?.data?.message);
            setCreateState(false);
            reset();
        }
    });

    // disable enable workflow
    const { refetch: getStatus } = useQuery(['disable', addObj], () => api.status(`/workflow/disable/${statusObj?.wf_code}/${statusObj?.wf_version}`, statusObj), {
        enabled: false,
        onSuccess: (data) => {
            data?.data?.code === 200 ? setError(false) : setError(true);
            setErrorMessage(data?.data?.message);
            setTimeout(() => refetchWFList(), 200);
        }
    });

    // get condition by id api
    const { refetch: refetchGetConditionById } = useQuery(['handleEdit', cObj], () => api.get(`condition/details/${cObj.cn_code}/${cObj.cn_version}`), {
        onSuccess: (editObj) => {
            const obj = {
                cn_name: dockerState === 'copy' ? '' : editObj?.data?.data[0]?.cn_name, cn_description: dockerState === 'copy' ? '' : editObj?.data?.data[0]?.cn_description, cn_mode: editObj?.data?.data[0]?.cn_mode,
                sa_id: editObj?.data?.data[0]?.sa_id, et_code: editObj?.data?.data[0]?.et_code, cn_status: editObj?.data?.data[0]?.cn_status, cn_rule: editObj?.data?.data[0]?.cn_rule, cn_version: editObj?.data.data[0]?.cn_version,
                cn_severity: editObj?.data.data[0]?.cn_severity, cn_code: editObj?.data.data[0]?.cn_code
            }
            setManualConditionObj(state => ({ ...state, ...obj }), []);
            manualState === 'copy' ? setShowManual('condition-popup') : setShowManual('condition');
        }, refetchOnWindowFocus: false, enabled: false
    });

    // get matrix by code
    const { refetch: getMatrixById } = useQuery(['getMatrixById', mObj], () => api.get(`matrix/${mObj.mx_code}/${mObj.mx_version}`), {
        enabled: false,
        onSuccess: (res) => {
            // const obj = res.data.data[0];
            // formCreateObject(obj);
            const obj = res?.data?.data[0];
            const app = find(appList, elm => elm.value === obj.sa_id);
            obj.sa_id = app;
            obj.mx_name = dockerState !== 'copy' ? obj.mx_name : '';;
            setManualMatrixObj(state => ({ ...state, ...obj }), []);
            setShowManual('matrix');
        }
    });

    useEffect(() => {
        !isEmpty(createObj.wf_name) && doesExistWorkflowRefetch();
        !isEmpty(createObj.sa_id) && eventRefetch();
    }, [createObj.wf_name, createObj.sa_id]);

    useEffect(() => {
        eventObj.et_code && eventObj?.et_name && doesExistEventRefetch();
    }, [eventObj.et_code, eventObj.et_name]);

    useEffect(() => {
        const disableSave = !(!eventExists && eventObj?.et_name?.length > 0 && eventObj?.et_code?.length > 0);
        setDisableEvent(disableSave);
    }, [eventObj, eventExists]);

    useEffect(() => {
        const dis = !(createObj?.wf_startDate && createObj?.et_code?.length > 0 && createObj?.sa_id?.length > 0 && createObj?.wf_details?.length > 0 && createObj?.wf_name?.length > 0);
        setDisabledSave(dis);
    }, [createObj]);

    useEffect(() => {
        errorMessage && setTimeout(() => setErrorMessage(''), 2000)
    }, [errorMessage]);

    useEffect(() => {
        if (inView) {
            if (startIndex < totalList - 20) {
                setStartIndex(startIndex + 20);
                setResponse(state => ({ ...state, startIndex: startIndex + 20 }), []);
            } else {
                return false;
            }
        } else {
            return false;
        }
    }, [inView]);

    useEffect(() => {
        loadPermission();
        setTimeout(() => setNorecord(true), 500)
    }, []);

    const handleSearch = (e) => {
        setShowDrop(false);
        const filter = e ? [e.obj] : [];
        e ? setSearchValue(e.value) : setSearchValue('');
        e ? setSearchInput(e.input) : setSearchInput('');
        setResponse(state => ({ ...state, filter: filter, startIndex: 0 }), []);
    }

    const handleToggle = (e, list) => {
        setShowDrop(false);
        const resObj = {
            wf_code: list.wf_code,
            wf_version: list.wf_version,
            wf_status: e ? 'A' : 'I'
        };
        statusObj = resObj;
        getStatus();
    }
    // handle tab click
    const handleTabClick = (e) => {
        setShowDrop(false);
        setTab(e.key);
        setResponse(state => ({ ...state, startIndex: 0, sort: [{ id: e.key, value: -1 }], filter: [] }), []);
        setTimeout(() => refetchWFList(), 200);
    }

    const handleAdd = (e) => {
        setShowDrop(false);
        setDocker(false);
        setSelectList([]);
        setActiveState('general');
        setDockerState('add')
        setbreadCrumb([...breadCrumb, 'General Details']);
        applicationRefetch();
    }

    const handleAddTab = (e) => {
        if ((e.key === 'workflow' && createObj.sa_id && createObj.et_code) || e.key === 'general') {
            const bread = [...breadCrumb];
            const state = ['add', 'copy'].includes(dockerState) ? 'Add' : dockerState === 'edit' ? 'Edit' : 'View'
            bread[1] = e.key === 'general' ? e.value : `${state} ${e.value}`;
            setActiveState(e.key);
            setbreadCrumb(bread);
            refetchConditionList();
            refetchMatrixList();
        } else {
            alert('Please fill application and event to proceed');
        }
    }

    const handleInput = ({ target: { name, value } }) => {
        setCreateObj(state => ({ ...state, [name]: value }), []);
    }

    const addEventInput = ({ target: { name, value } }) => {
        setEventObj(state => ({ ...state, [name]: value }), []);
        setEventObj(state => ({ ...state, sa_id: createObj.sa_id }));
    }

    const handleDrop = (name, value) => {
        name === 'sa_id' && setManualConditionObj(state => ({ ...state, sa_id: value }));
        if (name === 'et_code') {
            const ev = eventList?.filter(ele => ele?.value === value);
            setCreateObj(state => ({ ...state, et_name: ev[0]?.label }), []);
            setManualConditionObj(state => ({ ...state, et_code: value, et_name: ev[0]?.label }));
        }
        value !== 'add-event' ? setCreateObj(state => ({ ...state, [name]: value }), []) : handleAddEvent();
    }

    const handleDatepicker = (name, value) => {
        setCreateObj(state => ({ ...state, [name]: value }), []);
    }

    const handleConfimation = (e) => {
        setShowPopup(false);
        (e === 'Yes' && bottonState === 'Goback') && reset();
        (e === 'Yes' && bottonState === 'Save') && createWorkflow();
    }

    const handleBottomSection = (e) => {
        setBottonState(e);
        setShowPopup(true);
    }

    const handleAddEvent = () => {
        setAddEvent(true);
    }

    const handleCreatePopup = (e) => {
        e === 'Cancel' && setEventObj(eventCreateObj);
        e === 'Cancel' && setAddEvent(false);
        e === 'Create' && addEvent();
    }

    const handleChecked = (e, list) => {
        setShowDrop(false);
        if (e) {
            setDocker(true);
            setSelectList([list]);
        } else {
            setDocker(false);
            setSelectList([]);
        }
    }

    const dockerClick = (e, state) => {
        selectedCard = e;
        setDocker(false);
        setDockerState(state);
        applicationRefetch();
        refetchConditionList();
        refetchMatrixList();
        setSelectList([]);
        setbreadCrumb([...breadCrumb, 'General Details']);
        ['edit', 'copy', 'view'].includes(state) && getWorkflowById();
        // setbreadCrumb([...breadCrumb, 'General Details']);;
    }

    const handleStatus = (e, status) => {
        setCreateObj(state => ({ ...state, wf_mode: status }), [])
    }

    function formCreateObject(obj) {
        obj.wf_startDate = new Date(obj.wf_startDate);
        obj.wf_endDate = obj.wf_endDate ? new Date(obj.wf_endDate) : '';
        obj.wf_name = dockerState !== 'copy' ? obj.wf_name : '';
        obj.wf_details = orderBy(obj.wf_details, 'wf_priority');
        setCreateObj(obj, () => {
            eventRefetch();
        });
        setActiveState('general');
    }

    function createWorkflow() {
        const allowed = ['cn_code', 'cn_version', 'mx_code', 'mx_version', 'wf_message', 'apc_id', 'apc_version'];
        const workflow = createObj.wf_details.map(({ wf_rows, ...rest }) => {
            return {
                ...rest,
                wf_rows: wf_rows.map(elm => Object.fromEntries(allowed.map(k => [k, elm[k]])))
            }
        });
        const obj = cloneDeep(createObj);
        obj.wf_details = workflow;
        obj.wf_endDate = createObj?.wf_endDate ? moment(createObj?.wf_endDate)?.format('YYYY-MM-DD') : null;
        obj.wf_startDate = createObj?.wf_startDate ? moment(createObj?.wf_startDate)?.format('YYYY-MM-DD') : null;
        addObj = obj;
        if (dockerState === 'add' || dockerState === 'copy') {
            addWorkflow();
        } else {
            obj.wf_status = createObj?.wf_status ? createObj?.wf_status : 'A';
            obj.et_name = createObj.et_name;
            addObj = omit(obj, ['_id', 'updatedAt', 'updatedBy']);
            editWorkflow();
        }
    }

    const handleAddManually = (e) => {
        setManualState('add');
        e === 'condition' && setShowManual('condition-popup');
        e === 'matrix' && setShowManual('matrix-popup');
    }

    const handleManualPopup = (e) => {
        if (e === 'Create') {
            showManual === 'condition-popup' ? setShowManual('condition') : setShowManual('matrix');
        } else {
            setManualState('');
            setShowManual('');
        }
    }

    const handleResetManually = () => {
        setManualState('');
        setShowManual('');
        refetchConditionList();
        refetchMatrixList();
    }

    const handleManualActions = (dockerState, card, state) => {
        setManualState(dockerState);
        (['condition', 'Condition'].includes(state) && card[0]?.cn_code) && handleGetConditionById(card);
        (state === 'matrix' && card[0]?.mx_code) && handleGetMatrixById(card);
    }

    const handleGetConditionById = (e) => {
        cObj.cn_code = e[0]?.cn_code;
        cObj.cn_version = e[0]?.cn_version;
        refetchGetConditionById();
    }

    const handleGetMatrixById = (e) => {
        mObj.mx_code = e[0]?.mx_code;
        mObj.mx_version = e[0]?.mx_version;
        getMatrixById();
    }

    // handle toaster
    const handleToaster = (e) => {
        e === 'close' && setErrorMessage('');
    }

    const handleWFTabClick = (e, state) => {
        setActiveTabState(state);
        innerResObj.sort = [{ id: e.key, value: -1 }];
        state === 'condition' ? refetchConditionList() : refetchMatrixList();
    }

    const handleTitleDrop = (val) => {
        setShowDrop(val);
    }

    const handleTitleSelectedDrop = (val, key) => {
        setShowDrop(false);
        const filter = [{ id: key, value: val }]
        setTab(key);
        setResponse(state => ({ ...state, filter: filter, startIndex: 0 }), []);
    }

    function reset() {
        // setResponse(listRequestObj);
        refetchWFList();
        setActiveState('dashboard');
        setCreateObj(workflowCreateObj);
        refetchConditionList();
        refetchMatrixList();
        setAppList([]);
        setEventList([{ value: 'add-event', label: 'Add new event' }]);
        setbreadCrumb(['Workflows']);
        setEventExists(false);
        addObj = [];
    }

    if (isLoading) {
        if (startIndex === 0) {
            return <Loader loading={isLoading} />
        }
    }

    const cardList = workflowList?.length > 0 && map(workflowList, (el, i) => {
        return <CardComponent key={i} list={el} checked={selectlist[0]?._id === el._id} handleToggle={handleToggle} handleChecked={handleChecked} checkbox toggle toggleOn={el.wf_status === 'A' ? true : false} />
    })

    return (
        <>
            {!['condition', 'matrix'].includes(showManual) && <>
                {activeState === 'dashboard' &&
                    <>
                        <TitleSection title="Workflows" count={totalList} headerList={headerTab} tab search add={permission?.includes('ADD_WORK_FLOW')} activeTab={activeTab} handleTabClick={handleTabClick} handleAdd={handleAdd} searchList={workflowSearchList} handleSearch={handleSearch} cardList={totalList > 0 && workflowList} searchValue={searchValue} searchInput={searchInput} appList={appList} showDrop={showDrop} handleTitleDrop={handleTitleDrop} handleTitleSelectedDrop={handleTitleSelectedDrop} application={true} />
                        <CardRow>{cardList}</CardRow>
                        {workflowList?.length === 0 && norecord && <NoRecord></NoRecord>}
                        <span ref={ref} style={{ visibility: 'hidden' }}>Load More</span>
                    </>
                }

                {
                    (activeState === 'general' || activeState === 'workflow') &&
                    <div className="layout-wrap--bottom">
                        <BreadCrumb path={breadCrumb} />
                        <TabList tabList={workflowTab} tabActive={activeState} handleTabClick={handleAddTab} />
                        <WorkFlowContext.Provider value={{ createObj: [createObj, setCreateObj], matrixList: matrixList?.data?.data?.length > 0 && matrixList?.data?.data[0]?.result, conditionList: conditionList?.data?.data?.length > 0 && conditionList?.data?.data[0]?.result, dockerState }}>
                            {activeState === 'general' && <GeneralDetails doesExist={doesExistWorkflow?.data} appList={appList} eventList={uniqBy(eventList, 'value')} handleInput={handleInput} handleDrop={handleDrop} handleDatepicker={handleDatepicker} />}
                            {activeState === 'workflow' && <WorkFlow handleAddManually={handleAddManually} handleManualActions={handleManualActions} tabClick={handleWFTabClick} activeTabState={activeTabState} />}
                        </WorkFlowContext.Provider>
                        <BottomSection handleButtonClick={handleBottomSection} disableSave={disableSave || dockerState === 'view'} />
                    </div>
                }
            </>}

            {
                ['condition', 'condition-popup', 'matrix', 'matrix-popup'].includes(showManual) && <ManualFlow manualConditionObj={manualConditionObj} manualMatrixObj={manualMatrixObj} appList={appList} eventList={eventList} application={createObj?.sa_id}
                    event={createObj?.et_code} manualState={manualState} showManual={showManual} handleManualPopup={handleManualPopup} handleReset={() => handleResetManually()} />
            }

            {showDocker && <Docker dockerlist={dockerData} selectlist={selectlist} dockerClick={dockerClick} />}

            {showAddEvent && <AddEvents eventExists={eventExists} disableSave={disbleEvent} eventObj={eventObj} handleCreatePopup={handleCreatePopup} addEventInput={addEventInput} />}

            {showPopup && <PopupConfimation heading="Warning" content={bottonState === 'Goback' ? popup.back : dockerState === 'add' ? popup.save : dockerState === 'edit' ? popup.update : ''} showstatus={bottonState === 'Save'} activeStatus={createObj.wf_mode} handleConfimation={handleConfimation} handleChecked={handleStatus} />}

            {errorMessage && <Toaster success={!error} error={error} header={error ? 'Oops!!! something went wrong' : 'Yay! Everything Worked!'} content={typeof (errorMessage) === 'string' ? errorMessage : ''} handleUndoClose={handleToaster} />}
        </>
    )
}
