import { Images } from 'assets/Images';
import './styles.scss';

export default function GroupTemplate(props) {
  const { viewOnly } = props;
  return (
    <div className="group-template hide">
      <div className="options">
        <div className="option-absolute">
          <select className="logic" disabled={viewOnly}>
            <option value="ALL">AND</option>
            <option value="ANY">OR</option>
          </select>
          <button className="hook insert-group" disabled={viewOnly}>Add Group</button>
          <button className="hook insert-rule" disabled={viewOnly}>Add Rule</button>
          <button className="remove-group" disabled={viewOnly}><img src={Images.deleteIcon} alt="delete" /></button>
          <button className="drag-container" disabled={viewOnly}>{!viewOnly && <img src={Images.drag} alt="drag" className="drag-handler" />}
          </button>
        </div>
      </div>
      <div className="group-container"></div>
    </div>
  );
}