import { useState, useEffect } from 'react';
import { Textbox, Textarea, Button, Selectmenu } from 'components/generic';
import './styles.scss';
import { useContext } from 'react';
import { ReferenceContext } from 'providers';
import { find } from 'lodash';

export default function RuleModal(props) {

    const { name, description, severity, handleSubmit } = props;
    const [modalName, setModalName] = useState('');
    const [modalDesc, setModalDesc] = useState('');
    const [modalSeverity, setModalSeverity] = useState('')
    const referenceData = useContext(ReferenceContext);
    const severityList = find(referenceData?.referenceData, el => el.ard_severity)?.ard_severity?.map(ele => ({ label: ele.name, value: ele.value }));

    useEffect(() => {
        setModalName(name);
        setModalDesc(description);
        setModalSeverity(severity);
    }, [name, description, severity])

    // handle input for name and description
    const handleInput = (e) => {
        const { name, value } = e.target;
        name === 'Name' ? setModalName(value) : setModalDesc(value);
    }

    // handle severity
    const handleSeverity = (e) => {
        setModalSeverity(e.value);
    }

    // handle selected
    const getSelected = (list, id) => {
        return list?.filter(ele => ele.value === id);
    }

    return (
        <div className="modal">
            <Textbox label="Name" disabled={true} placeholder="Name" value={modalName} name="Name" onChange={handleInput} />
            <Selectmenu label='Severity' options={severityList} mandatory value={getSelected(severityList, modalSeverity)} onChange={handleSeverity} />
            <Textarea label="Description" placeholder="Description" value={modalDesc} name="Description" onChange={handleInput} />
            <div className="modal__row">
                <Button label="Cancel" xsmall ghost primary handleOnSubmit={() => handleSubmit('back')} />
                <Button label="Submit" xsmall primary handleOnSubmit={() => handleSubmit('save', modalName, modalDesc, modalSeverity)} />
            </div>
        </div>
    );
}