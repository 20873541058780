import axios from 'axios';
import { request } from 'utils';
import SimpleCrypto from "simple-crypto-js"

const { REACT_APP_EID } = process.env;
const headerOutToken = { 'e-id': REACT_APP_EID };

export const login = (obj) => axios.post('/login', obj, { headers: headerOutToken }).then(res => res?.data?.data);
export const logout = () => axios.get('logout', {
    headers: {
        'e-id': REACT_APP_EID,
        token: localStorage.getItem("_ac"),
        'refresh-token': localStorage.getItem("_rc")
    }
}).then(res => res);

export const authenticate = () => request({ url: '/authenticate', method: 'get' }).then(res => res);
export const getReferenceData = () => request({ url: `/referenceData`, method: 'get' }).then(res => res?.data?.data);

// general apis
export const getListing = (path, obj) => request({ url: path, method: 'post', data: obj }).then(res => res);
export const create = (path, obj) => request({ url: path, method: 'post', data: obj }).then(res => res).catch(err => err);
export const update = (path, obj) => request({ url: path, method: 'put', data: obj }).then(res => res).catch(err => err);
export const status = (path, obj) => request({ url: path, method: 'patch', data: obj }).then(res => res).catch(err => err);

// common apis
export const get = (path) => request({ url: path, method: 'get' }).then(res => res).catch(err => err);
export const post = (path, obj) => request({ url: path, method: 'post', data: obj }).then(res => res).catch(err => err);

// decrypt data
export const decrypting = (data) => {
    if (localStorage.getItem('_sc')) {
        const _sc = localStorage.getItem('_sc');
        const simpleCrypto = new SimpleCrypto(_sc);
        try {
            const decrypt = data ? simpleCrypto.decrypt(data) : '';
            return decrypt
        } catch (error) {

        }
    } else {
        logout();
    }
}
