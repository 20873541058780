import { useState, useContext, useEffect } from 'react';
import classNames from 'classnames';
import { map, isEmpty, find, filter } from 'lodash';
import { DragDropContainer, DropTarget } from 'react-drag-drop-container';
import { WorkFlowContext } from 'providers';
import { PriorityCard, CardComponent, CardRow, TitleSection, Button, CreateNew, PopupConfimation, Docker } from 'components/generic';
import { headerTab, dockerList } from 'config';
import { editStateObj, priorityUpdateObj } from 'interface';
import { Images } from 'assets/Images';
import * as api from 'actions';

import './style.scss';

export const WorkFlow = (props) => {

    const { handleAddManually, handleManualActions, tabClick, activeTabState } = props;

    const [showState, setshowState] = useState('');
    const { createObj, matrixList, conditionList, dockerState } = useContext(WorkFlowContext);
    const [dragList, setDraglist] = useState(conditionList);
    const [orgList, setOrglist] = useState(conditionList);
    const [setvalue, setCreateValue] = createObj;
    const [activeTab, setTab] = useState(headerTab[0].key);
    const [conditionState, setCondition] = useState([]);
    const [matrixState, setmatrix] = useState([]);
    const [message, setMessage] = useState(false);
    const [messageInput, setmessageInput] = useState('');
    const [priorityObj, setPriorityobj] = useState([]);
    const [addPriorityObj, setAddPriority] = useState([]);
    const [priorityIndex, setPriorityIndex] = useState(-1);
    const [currentIndex, setIndex] = useState(-1);
    const [editIndex, setEditIndex] = useState(-1);
    const [cardEdit, setCardEdit] = useState(true);
    const [editObj, setEditObj] = useState(editStateObj);
    const [editRowIndex, setEditRowIndex] = useState(-1);
    const [deleteIndex, setDeleteIndex] = useState({ rowIndex: -1, innerIndex: -1 });
    const [showPopup, setShowPopup] = useState(false);
    const [showDocker, setShowDocker] = useState(false);
    const [selectlist, setSelectList] = useState([]);
    const [messageIndex, setMessageIndex] = useState(1);
    const [checkedList, setCheckedList] = useState([]);
    const [checkedId, setCheckedId] = useState([]);

    const [condDockerData, setCondDockerData] = useState(dockerList);
    const [matDockerData, setMatDockerData] = useState(dockerList);

    useEffect(() => {
        if (!isEmpty(setvalue)) {
            setAddPriority(setvalue.wf_details);
        }
    }, [setvalue, editObj]);

    useEffect(() => {
        if (activeTabState === 'condition') {
            setDraglist(conditionList);
            setOrglist(conditionList);
        } else if (activeTabState === 'matrix') {
            setDraglist(matrixList);
            setOrglist(matrixList);
        }
    }, [activeTabState, activeTab, conditionList, matrixList]);

    useEffect(() => {
        loadPermission();
    }, []);

    const loadPermission = () => {
        const allPermissions = api.decrypting(localStorage.getItem('_ro'));
        const parsedPermission = allPermissions ? JSON?.parse(allPermissions) : {};
        const condPermission = parsedPermission?.exception_maintenance?.map(el => el?.replace(/_.*/, '')?.toLowerCase());
        const condDocker = dockerList.filter(el => condPermission?.includes(el.key));
        setCondDockerData(condDocker);

        const matPermission = parsedPermission?.matrix_maintenance?.map(el => el?.replace(/_.*/, '')?.toLowerCase());
        const matDocker = dockerList.filter(el => matPermission?.includes(el.key));
        setMatDockerData(matDocker);
    };

    // handle show add cards
    const handleWorkFlow = (value, i, pIndex) => {
        setshowState(value);
        setIndex(i);
        setPriorityIndex(pIndex);
        setvalue?.wf_details[pIndex] && setPriorityobj(setvalue?.wf_details[pIndex]?.wf_rows);
        switchListing(value);
    }

    const handlePlus = (value) => {
        setshowState(value);
        switchListing(value);
    }

    function switchListing(value) {
        if (value === 'condition') {
            setDraglist(conditionList);
            setOrglist(conditionList)
        } else {
            setDraglist(matrixList);
            setOrglist(matrixList);
        }
    }

    const addOrDropFunction = (el, type, state) => {
        setshowState(state);
        state === 'matrix' ? setDraglist(matrixList) : setDraglist(conditionList);
        if (state === 'condition') {
            if (cardEdit) {
                type === 'drop' ? setCondition(el.dragData) : type === 'add' ? setCondition(el) : setCondition('');
            } else {
                type === 'drop' ? setEditObj(state => ({ ...state, condition: el.dragData }), []) : type === 'add' ? setEditObj(state => ({ ...state, condition: el }), []) : setEditObj(state => ({ ...state, condition: {} }), []);
            }
        } else if (state === 'matrix') {
            if (cardEdit) {
                type === 'drop' ? setmatrix(el.dragData) : type === 'add' ? setmatrix(el) : setmatrix('');
            } else {
                type === 'drop' ? setEditObj(state => ({ ...state, matrix: el.dragData }), []) : type === 'add' ? setEditObj(state => ({ ...state, matrix: el }), []) : setEditObj(state => ({ ...state, matrix: {} }), []);
            }
        }
    }

    const editOrDropFunction = (value, i) => {
        setshowState(value);
        value === 'condition' ? setDraglist(conditionList) : setDraglist(matrixList);
        setEditObj(state => ({ ...state, [value]: {} }), []);
    }

    const handleWFTabClick = (e) => {
        const state = showState ? showState : 'condition';
        setTab(e.key);
        tabClick(e, state);
    }

    const handleMessage = (e, i) => {
        setMessageIndex(i);
        setshowState('message');
        setMessage(true);
    }

    const handleMessageInput = (e) => {
        setmessageInput(e.target.value);
    }

    const handleAddRow = () => {
        if (matrixState.mx_code && matrixState.mx_version) {
            const obj = {
                cn_code: conditionState.cn_code ? conditionState.cn_code : '',
                cn_name: conditionState.cn_name ? conditionState.cn_name : '',
                cn_version: conditionState.cn_version ? conditionState.cn_version : '',
                mx_code: matrixState.mx_code ? matrixState.mx_code : '',
                mx_name: matrixState.mx_name ? matrixState.mx_name : '',
                mx_version: matrixState.mx_version ? matrixState.mx_version : '',
                wf_message: messageInput ? messageInput : '',
                apc_version: null,
                apc_id: null
            }
            setPriorityobj(arr => [...arr, obj]);
            addPriorityObj[priorityIndex].wf_rows = [...priorityObj, obj];
            setCreateValue(state => ({ ...state, wf_details: addPriorityObj }), [])
            resetfun();
        }
    }

    function resetfun() {
        setCondition([]);
        setmatrix([]);
        setMessage(false);
        setMessageIndex(-1);
        setIndex(-1);
        setmessageInput('');
        setshowState('');
        setDraglist(conditionList);
        setCheckedList([]);
    }

    function editReset() {
        setCardEdit(true);
        setEditIndex(-1);
        setIndex(-1);
        setEditRowIndex(-1);
        setshowState('');
        setDraglist(conditionList);
        setCheckedList([]);
        setEditObj(editStateObj);
    }

    const handlePriorityAdd = () => {
        setPriorityIndex(addPriorityObj?.length)
        const obj = {
            wf_priority: addPriorityObj?.length + 1,
            wf_rows: []
        }
        setAddPriority(arr => [...arr, obj]);
        resetfun();
        setPriorityobj([]);
    }

    const handleEdit = (list, innerIndex, rowIndex) => {
        const condition = find(conditionList, el => (el.cn_code === list.cn_code && el.cn_version === list.cn_version));
        const matrix = find(matrixList, el => (el.mx_code === list.mx_code && el.mx_version === list.mx_version));
        setEditObj(state => ({ ...state, condition: condition }));
        setEditObj(state => ({ ...state, matrix: matrix }));
        setmessageInput(list.wf_message);
        setEditIndex(innerIndex);
        setEditRowIndex(rowIndex);
        setCardEdit(false);
    }

    const handleDelete = (innerIndex, rowIndex, list) => {
        setDeleteIndex({ rowIndex, innerIndex });
        setShowPopup(true);
    }

    const handlePriorityUpdate = (innerIndex, rowIndex) => {
        const { condition, matrix } = editObj;
        priorityUpdateObj.cn_code = condition?.cn_code || '';
        priorityUpdateObj.cn_name = condition?.cn_name || '';
        priorityUpdateObj.cn_version = condition?.cn_version || '';
        priorityUpdateObj.mx_code = matrix.mx_code;
        priorityUpdateObj.mx_name = matrix.mx_name;
        priorityUpdateObj.mx_version = matrix.mx_version;
        priorityUpdateObj.wf_message = messageInput;
        map(addPriorityObj, (el, i) => (i === rowIndex) && map(el.wf_rows, (elm, index) => (index === innerIndex) && Object.assign(elm, priorityUpdateObj)));
        setAddPriority(addPriorityObj);
        setCreateValue(state => ({ ...state, wf_details: addPriorityObj }), [])
        editReset();
    }

    const handlePriorityUpdateCancle = () => {
        editReset();
    }

    const handleConfimation = (e) => {
        if (e === 'Yes') {
            addPriorityObj[deleteIndex.rowIndex].wf_rows.splice(deleteIndex.innerIndex, 1);
            setPriorityobj(addPriorityObj[deleteIndex.rowIndex].wf_rows);
            const priority = addPriorityObj.filter(el => el?.wf_rows?.length > 0)?.filter((el, i) => el.wf_priority = i + 1);
            setCreateValue(state => ({ ...state, wf_details: priority }), []);
            resetfun();
        }
        setShowPopup(false);
    }

    const handleChecked = (e, list) => {
        if (e) {
            setShowDocker(true);
            setSelectList([list]);
        } else {
            setShowDocker(false);
            setSelectList([]);
        }
    }

    const dockerClick = (e, state) => {
        setShowDocker(false);
        setSelectList([]);
        ['edit', 'copy', 'view'].includes(state) && handleManualActions(state, selectlist, showState ? showState : 'condition');
    }

    const handleSearch = (e) => {
        if (e.target.value) {
            const filterList = showState === 'Condition' || showState === 'condition' || showState === '' ? filter(dragList, el => el?.cn_name?.toLowerCase()?.includes(e?.target?.value?.toLowerCase())) : filter(dragList, el => el?.mx_name?.toLowerCase()?.includes(e?.target?.value?.toLowerCase()));
            setDraglist(filterList);
        } else {
            setDraglist(orgList);
        }
    }

    const handleSelectCheckBox = (e, obj) => {
        if (e.target.checked) {
            const list = [...checkedList, obj];
            setCheckedId(list?.map(ele => ele?.wf_priority));
            setCheckedList(list);
        } else {
            const list = checkedList?.filter(ele => ele?.wf_priority !== obj?.wf_priority);
            setCheckedId(list?.map(ele => ele?.wf_priority));
            setCheckedList(list);
        }
    }

    const handleDeletePriority = (e, state) => {
        if (state === 'cancel') {
            setCheckedList([]);
            setCheckedId([]);
        } else if (state === 'delete') {
            setShowPopup(true);
        }
    }

    const handleDeletePriorityPopup = (e) => {
        if (e === 'No') {
            setCheckedList([]);
            setCheckedId([]);
        } else {
            const list = addPriorityObj?.filter(ele => !checkedId?.includes(ele?.wf_priority));
            list?.filter((ele, i) => ele.wf_priority = i + 1);
            setAddPriority(list);
            setCreateValue(state => ({ ...state, wf_details: list }), []);
            setCheckedList([]);
            setCheckedId([]);
        }
        setShowPopup(false);
    }

    if (isEmpty(addPriorityObj)) {
        return <CreateNew label="+ Add Priority" content="Click on button below to add new workflow" title="Start Creating" handleCreate={handlePriorityAdd} />;
    }

    return (
        <>
            <div className="row">
                <div className="col-9 scroll-bar" id="priority">
                    {map(addPriorityObj, (el, i) => {
                        return <PriorityCard key={i}>
                            <table id={i} className="priority-tabel">
                                <thead>
                                    <tr className='priority-tabel__header'>
                                        <th colSpan={4}> {dockerState !== 'view' && <input type="checkbox" checked={checkedId?.includes(i + 1)} onChange={(e) => handleSelectCheckBox(e, el)} />} Priority {el.wf_priority}</th>
                                    </tr>
                                    <tr className='priority-tabel__details'>
                                        <th className={(showState || message) ? 'center' : (editRowIndex === i) ? 'edit-head' : ''}>Exception</th>
                                        <th className={(showState || message) ? 'center' : (editRowIndex === i) ? 'edit-head' : ''}>Message</th>
                                        <th className={(showState || message) ? 'center' : (editRowIndex === i) ? 'edit-head' : ''}>Approval Matrix</th>
                                        {dockerState !== 'view' && (editRowIndex !== i) && <th className='edit'></th>}
                                    </tr>
                                </thead>
                                <tbody>
                                    {map(el.wf_rows, (elm, index) => {
                                        return <tr className="priority-tabel__genrow" key={index}>
                                            <td colSpan={5}>
                                                <table>
                                                    <tbody>
                                                        <tr>
                                                            {/* <td></td> */}
                                                            <td className={(showState || message) ? 'center' : (editIndex === index) && (editRowIndex === i) ? 'edit-head' : ''}>{elm.cn_name} {elm.cn_version && <span className="priority-tabel__version"> v{elm.cn_version}</span>}</td>
                                                            <td className={(showState || message) ? 'center' : (editIndex === index) && (editRowIndex === i) ? 'edit-head' : ''}>{elm.wf_message}</td>
                                                            <td className={(showState || message) ? 'center' : (editIndex === index) && (editRowIndex === i) ? 'edit-head' : ''}>{elm.mx_name} <span className="priority-tabel__version">v{elm.mx_version}</span></td>
                                                            {dockerState !== 'view' && (editIndex !== index) && (editRowIndex !== i) && <td className="edit-delete">
                                                                <img src={Images.editIcon} alt="edit" onClick={() => handleEdit(elm, index, i)} />
                                                                <img src={Images.deleteIcon} alt="delete" onClick={() => handleDelete(index, i, elm)} />
                                                            </td>}
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                {(editIndex === index) && (editRowIndex === i) && <table>
                                                    <tbody>
                                                        <tr className="priority-tabel__addrow">
                                                            {/* <td></td> */}
                                                            <td className={showState === 'condition' ? 'is-active' : 'is-normal'}>
                                                                {isEmpty(editObj?.condition) && <div className={classNames({ 'priority-tabel__add': true, 'priority-tabel__add--center': showState })} onClick={() => handlePlus('condition')} />}
                                                                <DropTarget targetKey="condition">
                                                                    {isEmpty(editObj?.condition) && showState === 'condition' && <div className="priority-tabel__dragarea" >
                                                                        <h4>Drag and drop files from right or click add button</h4>
                                                                        <h6>Or, if you prefer</h6>
                                                                        {/* <span onClick={(e) => handleAddManually('condition')}>Add Manually</span> */}
                                                                    </div>}
                                                                    {!isEmpty(editObj?.condition) && <CardRow full ><CardComponent list={editObj?.condition} border remove handleAddClick={(e) => editOrDropFunction('condition', index)} /></CardRow>}
                                                                </DropTarget>
                                                            </td>
                                                            <td>
                                                                <div className="priority-tabel__textarea">
                                                                    <textarea onChange={handleMessageInput} value={messageInput || ''} placeholder="Message..."></textarea>
                                                                </div>
                                                            </td>
                                                            <td className={showState === 'matrix' ? 'is-active' : 'is-normal'}>
                                                                {isEmpty(editObj?.matrix) && <div className={classNames({ 'priority-tabel__add': true, 'priority-tabel__add--center': showState })} onClick={() => handlePlus('matrix')} />}
                                                                <DropTarget targetKey="matrix">
                                                                    {isEmpty(editObj?.matrix) && showState === 'matrix' && <div className="priority-tabel__dragarea" >
                                                                        <h4>Drag and drop files from right or click add button</h4>
                                                                        <h6>Or, if you prefer</h6>
                                                                        {/* <span onClick={(e) => handleAddManually('matrix')}>Add Manually</span> */}
                                                                    </div>}
                                                                    {!isEmpty(editObj?.matrix) && <CardRow full ><CardComponent list={editObj?.matrix} border remove handleAddClick={(e) => editOrDropFunction('matrix', index)} /></CardRow>}
                                                                </DropTarget>
                                                            </td>
                                                            {/* <td></td> */}
                                                        </tr>
                                                        <tr>
                                                            <td colSpan={5}>
                                                                <Button primary transparent compact ghost label="Cancel" handleOnSubmit={handlePriorityUpdateCancle} />
                                                                <Button primary compact label="Update Priority" handleOnSubmit={(e) => handlePriorityUpdate(index, i)} disabled={isEmpty(editObj?.matrix)} />
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>}
                                            </td>
                                        </tr>
                                    })}
                                    {cardEdit && dockerState !== 'view' && <tr className="priority-tabel__addrow">
                                        <td className={showState === 'condition' ? 'is-active' : 'is-normal'}>
                                            {isEmpty(conditionState) && <div className={classNames({ 'priority-tabel__add': true, 'priority-tabel__add--center': showState })} onClick={() => handleWorkFlow('condition', el.wf_priority, i)} />}
                                            <DropTarget targetKey="condition">
                                                {(showState === 'condition' && isEmpty(conditionState) && currentIndex === el.wf_priority) && <div className="priority-tabel__dragarea">
                                                    <h4>Drag and drop files from right or click add button</h4>
                                                    <h6>Or, if you prefer</h6>
                                                    <span onClick={(e) => handleAddManually('condition')}>Add Manually</span>
                                                </div>}
                                                {(!isEmpty(conditionState) && currentIndex === el.wf_priority) && <CardRow full>
                                                    <CardComponent list={conditionState} border remove handleAddClick={(e) => addOrDropFunction(e, 'remove', 'condition')} /></CardRow>}
                                            </DropTarget>
                                        </td>
                                        <td>
                                            {!message && (currentIndex - 1 === i) && <div className={classNames({ 'priority-tabel__message': true, 'priority-tabel__message--left': showState === '', 'priority-tabel__message--center': showState })} onClick={(e) => handleMessage(e, i)}>Add Message</div>}
                                            {message && (i === messageIndex) && <div className="priority-tabel__textarea">
                                                <textarea onChange={handleMessageInput} placeholder="Message..."></textarea>
                                            </div>}
                                        </td>
                                        <td className={showState === 'matrix' ? 'is-active' : 'is-normal'}>
                                            {isEmpty(matrixState) && <div className={classNames({ 'priority-tabel__add': true, 'priority-tabel__add--center': showState })} onClick={() => handleWorkFlow('matrix', el.wf_priority, i)} />}
                                            <DropTarget targetKey="matrix">
                                                {(showState === 'matrix' && isEmpty(matrixState) && currentIndex === el.wf_priority) && <div className="priority-tabel__dragarea">
                                                    <h4>Drag and drop files from right or click add button</h4>
                                                    <h6>Or, if you prefer</h6>
                                                    <span onClick={(e) => handleAddManually('matrix')}>Add Manually</span>
                                                </div>}
                                                {(!isEmpty(matrixState) && currentIndex === el.wf_priority) && <CardRow full>
                                                    <CardComponent list={matrixState} border remove handleAddClick={(e) => addOrDropFunction(e, 'remove', 'matrix')} /></CardRow>}
                                            </DropTarget>
                                        </td>
                                    </tr>}
                                </tbody>
                            </table>
                            {cardEdit && dockerState !== 'view' && i === priorityIndex && (matrixState.mx_code && matrixState.mx_version) && <div className="priority-tabel__row" onClick={handleAddRow}> <span>Save</span> </div>}
                        </PriorityCard>
                    })}
                    {cardEdit && dockerState !== 'view' && (addPriorityObj?.length > 0 && addPriorityObj[addPriorityObj.length - 1].wf_rows.length > 0) && <Button id="add-priority" label={`Add Priority ${addPriorityObj.length + 1}`} primary transparent compact handleOnSubmit={handlePriorityAdd} />}
                </div>
                {dockerState !== 'view' && <div className="col-3 aside-bar">
                    <TitleSection title={showState === 'matrix' ? 'Approval Matrix' : 'Exception'} count={dragList?.length} headerList={headerTab} tab={true} activeTab={activeTab} handleTabClick={handleWFTabClick} />
                    <div className="card-search">
                        <div className="card-search__icon"> <img src={Images.search} alt="search" /></div>
                        <input type="text" placeholder="search" onChange={handleSearch} />
                    </div>

                    <div className="card-row scroll-bar">
                        <CardRow full>
                            {map(dragList, (el, i) => (
                                <DragDropContainer targetKey={showState} key={i} dragData={el} onDrop={(e) => addOrDropFunction(e, 'drop', showState)} dragClone={false} dragElemOpacity={1}>
                                    <CardComponent checked={selectlist[0]?._id === el?._id} checkbox list={el} add border handleAddClick={(e) => addOrDropFunction(e, 'add', showState)} handleChecked={handleChecked} />
                                </DragDropContainer>
                            ))}
                        </CardRow>
                    </div>
                </div>}
            </div>

            {showDocker && <Docker dockerlist={activeTabState === 'matrix' ? matDockerData : condDockerData} selectlist={selectlist} dockerClick={dockerClick} />}

            {checkedList?.length > 0 && <Docker dockerlist={[{ key: 'delete', src: Images.delete }]} multiSelect={true} selectlist={checkedList} dockerClick={handleDeletePriority} />}

            {showPopup && <PopupConfimation heading="Warning" content="Are you sure you want to delete?" handleConfimation={checkedId?.length > 0 ? handleDeletePriorityPopup : handleConfimation} />}
        </>
    )
}