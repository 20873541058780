import { Images } from 'assets/Images';

export const colors = ['#ff7777', '#91a6ea', '#e3b47d', '#7bdd92', '#6fbad6', '#ea8888', '#ea8888', '#b391ea', '#69a2f3', '#7bdd92'];

export const headerTab = [
    { key: 'topUsed', value: 'Top Used', type: 'tab' },
    { key: 'updatedAt', value: 'Recently Added', type: 'tab' },
    { key: 'sa_id', value: 'Application', type: 'dropdown' },
];

export const workflowTab = [
    { key: 'general', value: 'General Details' },
    { key: 'workflow', value: 'Workflow' },
];

export const matrixTab = [
    { key: 'general', value: 'General Details' },
    { key: 'matrix', value: 'Matrix' },
];

export const dockerList = [{ key: 'edit', src: Images.edit }, { key: 'copy', src: Images.copy }, { key: 'view', src: Images.view }];

export const WFdockerList = [{ key: 'edit', src: Images.edit }, { key: 'view', src: Images.view }];

export const listRequestObj = {
    'startIndex': 0,
    'recordCount': 20,
    'filter': [],
    'sort': [{ id: "topUsed", value: -1 }],
    'searchValue': ''
};

export const popup = {
    back: 'Do you want to go back to dashboard?',
    save: 'Are you sure you want to save?',
    update: 'Are you sure you want to update?',
};

export const newRule = { all: [] }

export const tabs = ['Facts', 'Expression'];

export const operatorList = [
    {
        type: 'default',
        operator: ['Equal', 'Not equal', 'Less than', 'Less than or equal', 'Greater than', 'Greater than or equal', 'Does not contain', 'Contains', 'Not in', 'In'],
        placeholder: 'Value',
    },
    {
        type: 'AN',
        operator: ['Equal', 'Not equal', 'Not in', 'In'],
        placeholder: 'Enter Value',
    },
    {
        type: 'NU',
        operator: ['Equal', 'Not equal', 'Less than', 'Greater than', 'Less than or equal', 'Greater than or equal', 'Not in', 'In'],
        placeholder: 'Enter a number',
    },
    {
        type: 'AR',
        operator: ['Contains', 'Does not contain', 'Not in', 'In'],
        placeholder: 'Eg. 10,20,30',
    },
];


export const apiOp = [
    {
        op_key: 'equal',
        op_name: 'Equal',
        op_type: ['string', 'numeric'],
        op_description: 'fact must equal value',
    },
    {
        op_key: 'notEqual',
        op_name: 'Not equal',
        op_type: ['string', 'numeric'],
        op_description: 'fact must not equal value',
    },
    {
        op_key: 'lessThan',
        op_name: 'Less than',
        op_type: ['numeric'],
        op_description: 'fact must be less than value',
    },
    {
        op_key: 'lessThanInclusive',
        op_name: 'Less than or equal',
        op_type: ['numeric'],
        op_description: 'fact must be less than or equal to value',
    },
    {
        op_key: 'greaterThan',
        op_name: 'Greater than',
        op_type: ['numeric'],
        op_description: ' fact must be greater than value',
    },
    {
        op_key: 'greaterThanInclusive',
        op_name: 'Greater than or equal',
        op_type: ['numeric'],
        op_description: 'fact must be greater than or equal to value',
    },
    {
        op_key: 'in',
        op_name: 'In',
        op_type: ['array'],
        op_description: '',
    },
    {
        op_key: 'notIn',
        op_name: 'Not in',
        op_type: ['array'],
        op_description: '',
    },
    {
        op_key: 'contains',
        op_name: 'Contains',
        op_type: ['array'],
        op_description: '',
    },
    {
        op_key: 'doesNotContain',
        op_name: 'Does not contain',
        op_type: ['array'],
        op_description: '',
    },
]
export const workflowSearchList = [
    { key: 'wf_name', value: 'Workflow Name' },
    { key: 'et_name', value: 'Event Name' },
    { key: 'sa_id', value: 'Application Name' },
    { key: 'wf_mode', value: 'Mode' },
    { key: 'wf_status', value: 'Status' },
    { key: 'updatedBy', value: 'Updated By' },
    { key: 'updatedAt', value: 'Updated At' },
]

export const matrixSearchList = [
    { key: 'mx_name', value: 'Matrix Name' },
    { key: 'mx_mode', value: 'Mode' },
    { key: 'sa_id', value: 'Application Name' },
    { key: 'mx_status', value: 'Status' },
    { key: 'updatedBy', value: 'Updated By' },
    { key: 'updatedAt', value: 'Updated At' },
]

export const conditionSearchList = [
    { key: 'cn_name', value: 'Exception Name' },
    { key: 'et_name', value: 'Event Name' },
    { key: 'sa_id', value: 'Application Name' },
    { key: 'cn_mode', value: 'Mode' },
    { key: 'cn_status', value: 'Status' },
    { key: 'cn_severity', value: 'Severity' },
    { key: 'updatedBy', value: 'Updated By' },
    { key: 'updatedAt', value: 'Updated At' },
]
