import classNames from 'classnames';

export default function CardRow(props){
    const { children, full, disabled } = props;
    return (
        <div className={classNames({'card': true, 'card--full': full, 'card--disabled': disabled})}>
            {children}
        </div>
    )
}
