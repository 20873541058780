import { useState, useEffect, useRef, useContext } from 'react';
// import PropTypes from 'prop-types';
import { map, find, isArray } from 'lodash';
import classNames from 'classnames';
import { Images } from 'assets/Images';
import { ReferenceContext } from 'providers';
import * as moment from 'moment';
import DatePickersRange from '../datepicker-range';


import './style.scss';

export default function FilterSearch(props) {
  const { last, searchList, searchFunction, value, input } = props;

  const [searchValue, setSearchValue] = useState('');
  const [searchKey, setSearchkey] = useState('');
  const [searchInput, setSearchInput] = useState('');
  const [showSearch, setShowSearch] = useState(false);
  const [showOptions, setShowSearchOptions] = useState(false);
  const [isSearchOpen, setSearchOpen] = useState(false);
  const [showDatepicker, setShowDatepicker] = useState(false);
  const [dateValue, setDateValue] = useState('');
  const [searchData, setSearchData] = useState([]);
  const [defaultDrop, setDefaultDrop] = useState(false);

  const referenceData = useContext(ReferenceContext);

  const status = find(referenceData?.referenceData, el => el.rd_status)?.rd_status?.map(ele => ({ key: ele.value, value: ele.label }));
  const mode = find(referenceData?.referenceData, el => el.rd_mode)?.rd_mode?.map(ele => ({ key: ele.value, value: ele.label }));
  const severity = find(referenceData?.referenceData, el => el.ard_severity)?.ard_severity?.map(ele => ({ key: ele.value, value: ele.name }));

  const ref = useRef();
  useOnClickOutside(ref, () => {
    setSearchOpen(false);
    setShowSearchOptions(false);
    setShowSearch(false);
  });

  useEffect(() => {
    isArray(input) ? setShowDatepicker(true) : setShowDatepicker(false);
    isArray(input) && setDateValue(input);
    input && setSearchInput(input);
    value && setSearchValue(value);
    const key = value ? searchList?.filter(ele => ele.value === value) : '';
    key?.length > 0 && setSearchkey(key[0]?.key);
  }, [input, value, searchList])

  const handleInput = (e) => {
    searchValue && setSearchInput(e.target.value);
  }

  const search = (e) => {
    e.code === 'Enter' && searchValue && searchFunction({ obj: { id: searchKey, value: searchInput }, value: searchValue, input: searchInput });
  }

  const handleSearchShow = () => {
    setShowSearch(true);
    !showDatepicker && document.getElementById('search').focus();
    if (!defaultDrop) {
      setShowSearchOptions(true);
      setSearchOpen(true);
      if (searchInput) {
        if (['wf_status', 'mx_status', 'cn_status', 'wf_mode', 'mx_mode', 'cn_mode', 'cn_severity'].includes(searchKey)) {
          ['wf_status', 'mx_status', 'cn_status'].includes(searchKey) && setSearchData(status);
          ['wf_mode', 'mx_mode', 'cn_mode'].includes(searchKey) && setSearchData(mode);
          searchKey === 'cn_severity' && setSearchData(severity);
        } else {
          setDefaultDrop(true);
          setShowSearchOptions(false);
          setSearchOpen(false);
        }
      } else {
        setSearchData(searchList);
      }
    }
  }

  const handleSelectedList = (list) => {
    if (searchValue === '') {
      setSearchkey(list.key);
      setSearchValue(list.value);
      getSearchValue(list);
    } else {
      setSearchInput(list.value)
      searchFunction({ obj: { id: searchKey, value: list.key }, value: searchValue, input: list.value });
      setShowSearchOptions(false);
      setSearchOpen(false);
    }
  }

  const getSearchValue = (list) => {
    switch (list.key) {
      case 'wf_status':
      case 'mx_status':
      case 'cn_status':
        // const status = find(referenceData?.referenceData, el => el.rd_status)?.rd_status?.map(ele => ({ key: ele.value, value: ele.label }));
        setSearchData(status);
        break
      case 'wf_mode':
      case 'cn_mode':
      case 'mx_mode':
        // const mode = find(referenceData?.referenceData, el => el.rd_mode)?.rd_mode?.map(ele => ({ key: ele.value, value: ele.label }));
        setSearchData(mode);
        break;
      case 'cn_severity':
        // const severity = find(referenceData?.referenceData, el => el.ard_severity)?.ard_severity?.map(ele => ({ key: ele.value, value: ele.name }));
        setSearchData(severity);
        break
      default:
        setShowSearch(false);
        setShowSearchOptions(false);
        setSearchOpen(false);
        setDefaultDrop(true)
        list.key === 'updatedAt' ? setShowDatepicker(true) : setShowDatepicker(false);
    }
  }

  const handleReset = () => {
    searchFunction();
    setSearchValue('');
    setSearchInput('');
    setSearchkey('');
    setShowSearch(false);
    setShowSearchOptions(false);
    setShowDatepicker(false);
    setDateValue('');
    setDefaultDrop(false);
    setSearchData(searchList);
  }


  const dateChange = (value) => {
    setSearchInput(value)
    setDateValue(value);
    const date = { from: moment(value[0]).format('YYYY/MM/DD'), to: moment(value[1]).format('YYYY/MM/DD') };
    searchFunction({ obj: { id: searchKey, value: date }, value: searchValue, input: value });
  }

  function useOnClickOutside(ref, handler) {
    useEffect(
      () => {
        const listener = (event) => {
          if (!ref.current || ref.current.contains(event.target)) {
            return;
          }
          handler(event);
        };
        document.addEventListener('mousedown', listener);
      },
      [ref, handler])
  }

  return (
    <>
      <div ref={ref} className={classNames({ 'search-row': true, 'search--last': last })}>
        <div className="search-row__icon"> <img src={Images.search} alt="search" /></div>
        {!showSearch && <div className="search-row__show" onClick={handleSearchShow}></div>}
        {searchValue && <div className="chips">
          <div className="chips__label">{searchValue}</div>
          <div className="chips__close" onClick={handleReset}>&times;</div>
        </div>}
        {showDatepicker && <DatePickersRange value={dateValue} onChange={dateChange} ></DatePickersRange>}
        {!showDatepicker && <input type="text" id="search" className="search-row__input" placeholder="Search" readOnly={!defaultDrop} value={searchInput} onChange={(e) => handleInput(e)} onKeyUp={(e) => search(e)}
          autoComplete="off" />}
        {(showOptions && isSearchOpen) && <div className="search-row__result scroll-bar" >
          <div className="search-row__selected" >{searchInput}
            <span className="search-row__reset" onClick={handleReset}>Reset</span>
          </div>
          <ul className="search-row__list">
            {map(searchData, (list, i) => (<li className="search-row__item" key={i} onClick={() => handleSelectedList(list)} >{list.value}
            </li>))}
            {(searchData?.length === 0 || !searchData) && <li className="search-row__item">No record found !</li>}
          </ul>
        </div>}
      </div>
    </>
  );
}

FilterSearch.propTypes = {

}

FilterSearch.defaultProps = {

}
