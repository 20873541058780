import { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';

import './style.scss';

const Header = (props) => {
    const [title, setTitle] = useState('');
    useEffect(() => {
        const pageName = props.history.location.pathname?.split('/');
        const result = pageName[pageName?.length - 1].replace(/-/g, " ");
        setTitle(result);
    }, [props]);

    return (
        <header className="header">
            <h2>{title}</h2>
        </header>
    )
}

export default withRouter(Header)

