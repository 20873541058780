import PropTypes from 'prop-types';
import './style.scss';

export default function Textarea(props) {
    const { id, type, name, value, placeholder, onChange, disabled, readonly, required, mandatory, maxlength, minlength, label,
        last } = props;
    return (
        <>
            <div className="textarea-row">
                <label>{label} {mandatory && <sup>*</sup>}</label>
                <textarea className="textarea-row__input" id={id} type={type} name={name} value={value}
                    placeholder={placeholder} disabled={disabled} readOnly={readonly} required={required}
                    maxLength={maxlength} minLength={minlength} last={last} onChange={onChange}></textarea>
            </div>
        </>
    )
}

Textarea.propTypes = {
    id: PropTypes.string,
    name: PropTypes.string,
    type: PropTypes.string,
    value: PropTypes.string,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    readOnly: PropTypes.bool,
    required: PropTypes.bool,
    minlength: PropTypes.number,
    maxlength: PropTypes.number,
    label: PropTypes.string,
    last: PropTypes.bool,
    mandatory: PropTypes.bool,
    onChange: PropTypes.func
}

Textarea.defaultProps = {
    id: '',
    type: '',
    name: '',
    value: '',
    last: null,
    readonly: false,
    label: '',
    required: false,
    placeholder: '',
    disabled: false,
    maxlength: 180,
    minlength: 0,
    mandatory: false,
    onChange: () => { }
}